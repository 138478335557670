import { useProjectTitle } from "@iolabs/app";
import { isMenuItemActive } from "@iolabs/utils";
import { useGlobalDialog } from "@iolabs/wip-dialog";
import {
    Avatar,
    Box,
    Button,
    ClickAwayListener,
    createStyles,
    Divider,
    IconButton,
    ListItemIcon,
    MenuItem,
    MenuList,
    Paper,
    Popover,
    Theme,
    Toolbar,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import SyncIcon from "@material-ui/icons/Sync";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { KeycloakProfile } from "keycloak-js";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useKeycloak } from "react-keycloak";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { Path } from "../../pages/PageRouter/PageRouter";
import { defaultDrawerWidth, defaultHeaderHeight } from "../Layout/PrivateLayout";
import messages from "./messages";

type StylesProps = {
    headerHeight?: string;
    logoWidth?: string;
    logoHeight?: string;
    burgerButtonColor?: string;
    backButtonColor?: string;
    headingColor?: string;
    companyColor?: string;
    projectColor?: string;
    avatarColor?: string;
    avatarBackgroundColor?: string;
    avatarSize?: string;
    menuColor?: string;
    menuIconColor?: string;
    menuBackgroundColor?: string;
    privateHeader?: string;
    dividerBackgroundColor?: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: (props: StylesProps | undefined) => ({
            flexWrap: "wrap",
            minHeight: props?.headerHeight ? props?.headerHeight : defaultHeaderHeight,
            height: props?.headerHeight ? props?.headerHeight : defaultHeaderHeight,
            paddingLeft: 0,
            [theme.breakpoints.up("sm")]: {
                flexWrap: "nowrap",
                paddingRight: "1rem",
            },
        }),
        burgerIcon: {
            width: "20px",
            height: "20px",
        },
        logo: {
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            zIndex: 2,
            color: theme.palette.common.white,
            background: theme.palette.primary.main,
            padding: `0 ${theme.spacing(2)}`,
            width: "60px",
            "& a": {
                display: "flex",
                flowFlow: "row nowrap",
                color: "inherit",
                fontWeight: "bold",
                whiteSpace: "nowrap",
                textTransform: "none",
                fontSize: "1rem",
            },
            [theme.breakpoints.up("sm")]: {
                width: "auto",
                minWidth: defaultDrawerWidth,
            },
        },
        logoLabel: {
            marginLeft: theme.spacing(1),
            display: "none",
            [theme.breakpoints.up("sm")]: {
                display: "inline-block",
            },
        },
        iconButton: {
            color: theme.palette.grey[500],
            padding: "8px",
            marginRight: theme.spacing(2),
            width: "30px",
            height: "30px",
        },
        iconButtonActive: {
            color: theme.palette.common.white,
            background: theme.palette.primary.main,
            "&:hover": {
                color: theme.palette.common.white,
                background: theme.palette.primary.main,
            },
        },
        menu: {
            flex: "1 1 0%",
            textAlign: "right",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "flex-end",
            alignItems: "center",
            order: 1,
            [theme.breakpoints.up("sm")]: {
                order: 2,
                flexBasis: "30%",
            },
            [theme.breakpoints.up("lg")]: {
                flexBasis: "50%",
            },
        },
        menuButton: {
            minWidth: "auto",
            paddingLeft: 0,
            paddingRight: 0,
            "&:hover": {
                backgroundColor: "inherit",
            },
        },
        company: (props: StylesProps | undefined) => ({
            color: props?.companyColor ? props?.companyColor : theme.palette.common.white,
            textTransform: "uppercase",
            fontSize: "1.1rem",
            fontWeight: 100,
            [theme.breakpoints.up("sm")]: {
                fontSize: "1.2rem",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "1.2rem",
            },
        }),
        popper: {
            zIndex: 10,
        },
        usernameBox: {
            [theme.breakpoints.up("sm")]: {
                display: "none",
            },
        },
        username: {
            display: "none",
            lineHeight: "1rem",
            textAlign: "center",
            marginRight: theme.spacing(2),
            [theme.breakpoints.up("sm")]: {
                display: "block",
            },
        },
        avatar: (props: StylesProps | undefined) => ({
            color: props?.avatarColor ? props?.avatarColor : theme.palette.common.white,
            backgroundColor: props?.avatarBackgroundColor ? props?.avatarBackgroundColor : theme.palette.primary.main,
            textTransform: "uppercase",
            width: props?.avatarSize ? props?.avatarSize : "30px",
            height: props?.avatarSize ? props?.avatarSize : "30px",
            fontSize: "1rem",
        }),
        menuList: (props: StylesProps | undefined) => ({
            color: props?.menuColor ? props?.menuColor : theme.palette.text.primary,
            backgroundColor: props?.menuBackgroundColor ? props?.menuBackgroundColor : theme.palette.common.white,
            minWidth: theme.spacing(18),
            [theme.breakpoints.up("sm")]: {
                "& li": {
                    justifyContent: "flex-start",
                },
            },
        }),
        menuBox: {
            display: "block",
            [theme.breakpoints.up("lg")]: {
                display: "none",
            },
        },
        listItemIcon: {
            minWidth: "34px",
        },
        divider: (props: StylesProps | undefined) => ({
            backgroundColor: props?.dividerBackgroundColor ? props?.dividerBackgroundColor : theme.palette.grey["200"],
        }),
        hide: {
            display: "none",
        },
    })
);

export type IUserMenuItem = {
    title: string;
    path: string;
    icon: ReactNode;
    wip?: false;
};

interface IHeaderProps {
    userMenuItems: IUserMenuItem[];
    rightMenu?: React.ReactNode;
    stylesProps?: StylesProps;
}

const PrivateHeader: React.FC<IHeaderProps> = (props: IHeaderProps) => {
    const { userMenuItems, stylesProps } = props;

    const classes = useStyles(stylesProps);
    const [menuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [userData, setUserData] = useState<KeycloakProfile | null>(null);
    const history = useHistory();
    const location = useLocation();
    const { keycloak } = useKeycloak();
    const { setGlobalDialogOpen } = useGlobalDialog();
    const projectTitle = useProjectTitle();

    const handleMenuToggle = () => {
        setMenuOpen(prevMenuOpen => !prevMenuOpen);
    };

    const handleMenuClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setMenuOpen(false);
    };

    const handleSignOut = () => {
        history.push("/");
        keycloak.logout();
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === "Tab") {
            event.preventDefault();
            setMenuOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevMenuOpen = React.useRef(menuOpen);

    useEffect(() => {
        Promise.resolve(keycloak.loadUserProfile()).then(data => {
            setUserData(data as KeycloakProfile);
        });

        if (prevMenuOpen.current && !menuOpen) {
            anchorRef.current!.focus();
        }

        prevMenuOpen.current = menuOpen;
    }, [keycloak, menuOpen]);

    // translations
    const intl = useIntl();
    const transSignOut = intl.formatMessage({ ...messages.signOut });

    return (
        <Toolbar className={classes.root}>
            <Box className={classes.logo}>
                <Button disableRipple variant="text" component={RouterLink} to={Path.MAPPINGS}>
                    <SyncIcon style={{ transform: "scaleX(-1) rotate(-45deg)" }} />
                    <span className={classes.logoLabel}>My Mappings</span>
                </Button>
            </Box>

            <Box className={classes.menu}>
                {userData && (
                    <>
                        <IconButton
                            color="primary"
                            component={RouterLink}
                            to={Path.AUTHORIZATION}
                            aria-label="Settings"
                            className={clsx(classes.iconButton, {
                                [classes.iconButtonActive]: isMenuItemActive(location.pathname, Path.SETTINGS),
                            })}
                        >
                            <SettingsIcon fontSize="small" />
                        </IconButton>

                        <Typography color="textPrimary" className={classes.username}>
                            {`${userData!.firstName} ${userData!.lastName}`}
                        </Typography>

                        <Button
                            ref={anchorRef}
                            aria-controls={menuOpen ? "menu-list-grow" : undefined}
                            aria-haspopup="true"
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            variant="text"
                            onClick={handleMenuToggle}
                            className={classes.menuButton}
                        >
                            <Avatar className={classes.avatar}>
                                {`${userData!.firstName!.charAt(0)}${userData!.lastName!.charAt(0)}`}
                            </Avatar>
                        </Button>
                        <Popover
                            open={menuOpen}
                            anchorEl={anchorRef.current}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            className={classes.popper}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleMenuClose}>
                                    <MenuList
                                        autoFocusItem={menuOpen}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                        className={classes.menuList}
                                    >
                                        <Box className={classes.usernameBox}>
                                            <MenuItem disabled>{`${userData!.firstName} ${
                                                userData!.lastName
                                            }`}</MenuItem>
                                            <Divider className={classes.divider} />
                                        </Box>

                                        <Box className={classes.menuBox}>
                                            {projectTitle && (
                                                <>
                                                    <MenuItem>{`${projectTitle}`}</MenuItem>
                                                    <Divider className={classes.divider} />
                                                </>
                                            )}
                                        </Box>

                                        {userMenuItems.map((item, index) => {
                                            return item.wip ? (
                                                <MenuItem key={index} onClick={() => setGlobalDialogOpen(true)}>
                                                    <ListItemIcon className={classes.listItemIcon}>
                                                        {item.icon}
                                                    </ListItemIcon>
                                                    <Typography variant="inherit">{item.title}</Typography>
                                                </MenuItem>
                                            ) : (
                                                <MenuItem key={index} component={RouterLink} to={item.path}>
                                                    <ListItemIcon className={classes.listItemIcon}>
                                                        {item.icon}
                                                    </ListItemIcon>
                                                    <Typography variant="inherit">{item.title}</Typography>
                                                </MenuItem>
                                            );
                                        })}

                                        <MenuItem onClick={handleSignOut}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <ExitToAppIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography variant="inherit">{transSignOut}</Typography>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Popover>
                    </>
                )}
            </Box>
        </Toolbar>
    );
};

export default PrivateHeader;
