import { Box, createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { FC } from "react";
import { useField } from "react-final-form";
import { StorageType } from "../../api/types";
import imgBim360GS from "../../assets/images/logo-bim360-gs.png";
import imgBim360 from "../../assets/images/logo-bim360.png";
import imgSharepointGS from "../../assets/images/logo-sharepoint-gs.png";
import imgSharepoint from "../../assets/images/logo-sharepoint.png";

const useStyles = makeStyles(() =>
    createStyles({
        platformImages: {
            display: "flex",
            "& > div": {
                position: "relative",
                cursor: "pointer",
            },
        },
        imgStatus: {
            position: "absolute",
            left: "-4px",
            top: "-4px",
        },
    })
);

interface Props {
    name: string;
}

export const PlatformSelect: FC<Props> = ({ name }) => {
    const classes = useStyles();
    const { input } = useField(name);

    return (
        <Box className={classes.platformImages}>
            <Box mr={2} onClick={() => input.onChange(StorageType.Forge)}>
                <img src={input.value === StorageType.Forge ? imgBim360 : imgBim360GS} alt="BIM360" height={50} />
                {/*<img src={imgCheck} alt="Valid" className={classes.imgStatus} />*/}
            </Box>
            <Box mr={2} onClick={() => input.onChange(StorageType.SharePoint)}>
                <img
                    src={input.value === StorageType.SharePoint ? imgSharepoint : imgSharepointGS}
                    alt="SharePoint"
                    height={50}
                />
                {/*<img src={imgCheck} alt="Valid" className={classes.imgStatus} />*/}
            </Box>
        </Box>
    );
};
