import { IMenuItem, IUserMenuItem } from "@iolabs/layout";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import PaymentIcon from "@material-ui/icons/Payment";
import PersonIcon from "@material-ui/icons/Person";
import RestoreIcon from "@material-ui/icons/Restore";
import StorageOutlinedIcon from "@material-ui/icons/StorageOutlined";
import React, { ReactNode } from "react";
import { useKeycloak } from "react-keycloak";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import PrivateLayout from "../../components/Layout/PrivateLayout";
import PublicLayout from "../../components/Layout/PublicLayout";
import Loading from "../../components/Loading/Loading";
import ErrorPage from "../ErrorPage/ErrorPage";
import AuthorizationPage from "../private/AuthorizationPage/AuthorizationPage";
import BillingPage from "../private/BillingPage/BillingPage";
import HistoryPage from "../private/HistoryPage/HistoryPage";
import LogFilePage from "../private/LogFilePage/LogFilePage";
import ManagementPage from "../private/ManagementPage/ManagementPage";
import CreateMappingPage from "../private/MappingPage/CreateMappingPage";
import MappingPage from "../private/MappingPage/MappingPage";
import MyJobsPage from "../private/MyJobsPage/MyJobsPage";
import MyProfilePage from "../private/MyProfilePage/MyProfilePage";
import AppPage from "../public/AppPage/AppPage";
import ExplorePage from "../public/ExplorePage/ExplorePage";
import HomePage from "../public/HomePage/HomePage";
import LoginPage from "../public/LoginPage/LoginPage";
import PricingPage from "../public/PricingPage/PricingPage";
import TermsPage from "../public/TermsPage/TermsPage";
import TipsPage from "../public/TipsPage/TipsPage";
import WorksPage from "../public/WorksPage/WorksPage";
import logo from "./../../assets/images/logo-mapper.svg";
import { PrivateRoute } from "./PrivateRoute";
import DashboardPage from "../private/DashboardPage/DashboardPage";
import EditMappingPage from "../private/MappingPage/EditMappingPage";

export type PageRoute = {
    label: string; // label showing in menu
    isHidden?: boolean; // is hidden in menu
    path: string;
    component?: ReactNode;
    showMenu?: boolean;
    exact?: boolean;
    private: boolean; // authorization needed for access
    routes?: PageRoute;
};

export enum Path {
    // general paths
    ROOT = "/",
    LOGOUT = "/logout",

    // public paths
    WORKS = "/how-it-works",
    APP = "/app",
    EXPLORE = "/explore",
    TIPS = "/tips",
    PRICING = "/pricing",
    TERMS_OF_SERVICES = "/terms-of-services",
    LOGIN = "/login",
    SIGN_UP = "/sign-up",

    // private paths
    MAPPINGS = "/mappings",
    MAPPINGS_CREATE = "/mappings/create",
    MAPPINGS_EDIT = "/mappings/edit/",
    SETTINGS = "/settings",
    AUTHORIZATION = "/settings/authorization",
    MY_PROFILE = "/settings/my-profile",
    MY_JOBS = "/settings/my-jobs",
    BILLING = "/settings/billing",
    LOG_FILE = "/settings/log-file",
}

export const menuItems: IMenuItem[] = [
    /*{
        title: "My Mappings",
        path: Path.MAPPINGS,
        icon: <SyncIcon style={{ transform: "scaleX(-1) rotate(-45deg)" }} />,
    },*/
    {
        title: "My Profile",
        path: Path.MY_PROFILE,
        icon: <PersonIcon />,
    },
    {
        title: "My Jobs",
        path: Path.MY_JOBS,
        icon: <RestoreIcon />,
    },
    {
        title: "Authorization",
        path: Path.AUTHORIZATION,
        icon: <HowToRegIcon />,
    },
    {
        title: "Billing",
        path: Path.BILLING,
        icon: <PaymentIcon />,
    },
    {
        title: "Log File",
        path: Path.LOG_FILE,
        icon: <StorageOutlinedIcon />,
    },
];

export const userMenuItems: IUserMenuItem[] = [
    // {
    //     title: "Settings",
    //     path: "/settings",
    //     icon: <SettingsIcon fontSize="small" />,
    // },
];

export const routes: PageRoute[] = [
    // public routes
    {
        label: "Home",
        isHidden: true,
        private: false,
        path: Path.ROOT,
        exact: true,
        component: HomePage,
    },
    {
        label: "How It Works",
        private: false,
        path: Path.WORKS,
        component: WorksPage,
    },
    {
        label: "App",
        private: false,
        path: Path.APP,
        component: AppPage,
    },
    {
        label: "Explore",
        private: false,
        path: Path.EXPLORE,
        component: ExplorePage,
    },
    {
        label: "Tips",
        private: false,
        path: Path.TIPS,
        component: TipsPage,
    },
    {
        label: "Pricing",
        private: false,
        path: Path.PRICING,
        component: PricingPage,
    },
    {
        label: "Terms of services",
        isHidden: true,
        private: false,
        path: Path.TERMS_OF_SERVICES,
        component: TermsPage,
    },
    {
        label: "Login",
        private: false,
        path: Path.LOGIN,
        component: LoginPage,
    },
    {
        label: "Sign Up",
        private: false,
        path: Path.SIGN_UP,
        component: LoginPage,
    },

    // private routes
    {
        label: "My Mappings",
        private: true,
        path: Path.MAPPINGS,
        exact: true,
        component: MappingPage,
        showMenu: false,
    },
    {
        label: "Create Mappings",
        private: true,
        path: Path.MAPPINGS_CREATE,
        exact: true,
        component: CreateMappingPage,
        showMenu: false,
    },
    {
        label: "Edit Mappings",
        private: true,
        path: Path.MAPPINGS_EDIT + ":id",
        exact: true,
        component: EditMappingPage,
        showMenu: false,
    },
    {
        label: "My Profile",
        private: true,
        path: Path.MY_PROFILE,
        exact: true,
        component: MyProfilePage,
        showMenu: true,
    },
    {
        label: "My Jobs",
        private: true,
        path: Path.MY_JOBS,
        exact: true,
        component: MyJobsPage,
        showMenu: true,
    },
    {
        label: "Authorization",
        private: true,
        path: Path.AUTHORIZATION,
        exact: true,
        component: AuthorizationPage,
        showMenu: true,
    },
    {
        label: "Billing",
        private: true,
        path: Path.BILLING,
        exact: true,
        component: BillingPage,
        showMenu: true,
    },
    {
        label: "Log File",
        private: true,
        path: Path.LOG_FILE,
        exact: true,
        component: LogFilePage,
        showMenu: true,
    },
    // old
    {
        label: "History",
        private: true,
        path: "/history",
        component: HistoryPage,
        showMenu: true,
    },
    {
        label: "Management",
        private: true,
        path: "/management",
        component: ManagementPage,
        showMenu: true,
    },
    {
        label: "Dashboard",
        private: true,
        path: "/dashboard",
        component: DashboardPage,
        showMenu: true,
    },
];

export const publicRoutes = routes.filter(route => !route.private);
export const privateRoutes = routes.filter(route => route.private);

export const RouteWithSubRoutes = route => {
    return !route.private ? (
        <Route
            path={route.path}
            exact={route.exact}
            component={props => (
                <PublicLayout
                    fullWidth={route.path === Path.LOGIN || route.path === Path.SIGN_UP}
                    noPadding={route.path === Path.LOGIN || route.path === Path.SIGN_UP}
                >
                    <route.component {...props} routes={route.routes} />
                </PublicLayout>
            )}
        />
    ) : (
        <PrivateRoute
            loginPath={Path.LOGIN}
            path={route.path}
            exact={route.exact}
            component={props => (
                <PrivateLayout route={route} logo={logo} menuItems={menuItems} userMenuItems={userMenuItems}>
                    <route.component {...props} routes={route.routes} />
                </PrivateLayout>
            )}
        />
    );
};

const PageRouter: React.FC = () => {
    const { initialized } = useKeycloak();

    if (!initialized) {
        return <Loading />;
    }

    return (
        <Router>
            <LastLocationProvider>
                <Switch>
                    {routes.map((route: PageRoute, index: number) => (
                        <RouteWithSubRoutes key={index} {...route} />
                    ))}
                    <Route path={Path.LOGIN} component={LoginPage} />
                    <Route component={ErrorPage} />
                </Switch>
            </LastLocationProvider>
        </Router>
    );
};

export default PageRouter;
