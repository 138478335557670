import { Box, Button, Container, createStyles, Theme } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { Form } from "react-final-form";
import { NavLink as RouterLink, useHistory } from "react-router-dom";
import { createMainJob } from "../../../api/client";
import { StorageType } from "../../../api/types";
import { Path } from "../../PageRouter/PageRouter";
import { MappingBox } from "./MappingBox";
import { StorageBox } from "./StorageBox";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            background: theme.palette.grey["100"],
            minHeight: "100%",
        },
        button: {
            textTransform: "inherit",
            paddingTop: theme.spacing(0.8),
            paddingBottom: theme.spacing(0.8),
        },
        platformBox: {
            display: "flex",
            flexFlow: "column nowrap",
            [theme.breakpoints.up("md")]: {
                flexFlow: "row nowrap",
            },
        },
    })
);

interface FormDataStorage {
    type: StorageType;
    project: string;
    root: {
        id: string;
        path: string;
    }[];
}

interface FormData {
    master: FormDataStorage;
    slave: FormDataStorage;
    name: string;
    includes: { path: string }[];
    excludes: string[];
    repeat: string;
    time: number;
    syncSlave: boolean;
}

const CreateMappingPage: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Container maxWidth={false} classes={{ root: classes.root }}>
            <Form
                onSubmit={async (v: FormData) => {
                    const result = await createMainJob({
                        name: v.name,
                        master: {
                            type: v.master?.type,
                            projectId: v.master?.project,
                            rootFolderId: v.master?.root[0]?.id,
                            rootFolderPath: v.master?.root[0]?.path,
                        },
                        slave: {
                            type: v.slave?.type,
                            projectId: v.slave?.project,
                            rootFolderId: v.slave?.root[0]?.id,
                            rootFolderPath: v.slave?.root[0]?.path,
                        },
                        excludes: v.excludes,
                        includes: v.includes.map(node => node.path),
                        repeat: v.repeat || "daily",
                        time: v.time || 0,
                        syncSlave: v.syncSlave,
                    });

                    if (result === null) {
                        alert("Error");
                    } else {
                        history.push(Path.MAPPINGS);
                    }
                }}
            >
                {({ handleSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Box mt={1} mb={1} display="flex" justifyContent="flex-end">
                            <Button
                                variant="contained"
                                color="default"
                                className={classes.button}
                                startIcon={<ArrowBackIcon />}
                                component={RouterLink}
                                to={Path.MAPPINGS}
                            >
                                Cancel
                            </Button>
                        </Box>

                        <Box className={classes.platformBox}>
                            <StorageBox master={true} values={values} />
                            <MappingBox
                                active={values.master?.root.length > 0 && values.slave?.root.length > 0}
                                values={values}
                            />
                            <StorageBox master={false} values={values} />
                        </Box>
                    </form>
                )}
            </Form>
        </Container>
    );
};

export default CreateMappingPage;
