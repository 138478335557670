import { Typography } from "@material-ui/core";
import React from "react";
import PublicContainer from "../../../components/Layout/PublicContainer";

// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         root: {},
//     })
// );

const WorksPage: React.FC = () => {
    // const classes = useStyles();
    // const dispatch = useDispatch<DispatchAction>();

    // translations
    // const intl = useIntl();
    // const transPageTitle = intl.formatMessage({ ...messages.pageTitle });

    return (
        <PublicContainer>
            <Typography variant="h1">How It Works Page</Typography>

            <br />
            <br />
            <br />

            <Typography variant="h1">Heading - h1</Typography>

            <br />

            <Typography variant="h2">Heading - h2</Typography>

            <br />

            <Typography variant="h3">Heading - h3</Typography>

            <br />

            <Typography variant="h4">Heading - h4</Typography>

            <br />

            <Typography variant="h5">Heading - h5</Typography>

            <br />

            <Typography variant="h6">Heading - h6</Typography>

            <br />

            <Typography variant="subtitle1">Subtitle1</Typography>

            <br />

            <Typography variant="subtitle2">Subtitle2</Typography>

            <br />

            <Typography variant="body1">
                Body 1 - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium commodi dolor eius error
                harum nihil numquam possimus quidem tempora. Aut delectus fugit itaque praesentium ratione? Consectetur
                error est quam quia.
            </Typography>

            <br />

            <Typography variant="body2">
                Body 2 - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis dolor doloribus et eum
                itaque necessitatibus nisi reprehenderit. Aspernatur, blanditiis debitis dicta enim error est magni nisi
                qui reiciendis sit, soluta!
            </Typography>

            <br />

            <Typography variant="button">Button</Typography>

            <br />

            <Typography variant="caption">Caption</Typography>

            <br />

            <Typography variant="overline">Overline</Typography>
        </PublicContainer>
    );
};

export default WorksPage;
