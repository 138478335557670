export enum StorageType {
    SharePoint = "SharePoint",
    Forge = "Forge",
}

export interface Storage {
    id: number;
    name: string;
    type: StorageType;
    config: {
        RootPath: string;
    };
}

export interface Job {
    id: number;
    master: Storage;
    slave: Storage;
    code: string;
    name: string;
    include: string;
    exclude: string;
    createdAt: string;
    startedAt: string | null;
    finishedAt: string | null;
    cancelled: boolean;
}

export interface MainJob {
    id: number;
    master: Storage;
    slave: Storage;
    name: string;
    includes: string[];
    excludes?: string[];
    expression: string;
    repeat: string;
    time: number;
    active: boolean;
    syncSlave: boolean;
}

export interface SyncLog {
    id: number;
    source: Storage | null;
    destination: Storage | null;
    level: string;
    message: string;
    platform: string;
    action: string;
    createdAt: string;
}

export interface SyncLogQuery {
    level: number | string;
    action: string;
}

export interface StorageRequest {
    type: StorageType;
    projectId: string;
    rootFolderId: string;
    rootFolderPath: string;
    // folderPaths: string[];
}

export interface MainJobRequest {
    name: string;
    master?: StorageRequest;
    slave?: StorageRequest;
    includes: string[];
    excludes: string[];
    repeat: string;
    time: number;
    syncSlave: boolean;
}

export interface ForgeHook {
    hookId: string;
    callbackUrl: string;
    event: string;
}

export interface SharePointHook {
    id: string;
    notificationUrl: string;
    changeType: string;
    resource: string;
    expirationDateTime: string;
}

// platform config
export interface PlatformConfig {
    id: number;
    type: "Microsoft" | "Forge";
    clientId: string;
    clientSecret: string;
}

// tree
export interface TreeNode {
    id: string;
    text: string;
    type: string;
    data?: {
        type: "hub";
        urn: string;
    };
    children?: TreeNode[];
}

// tmp node
export interface Node {
    id: number;
    storage: { id: number; name: string; config: string };
    nodeId: string;
    path: string;
    folder: boolean;
    updatedAt: string;
}
