import { isMenuItemActive } from "@iolabs/utils";
import { useGlobalDialog } from "@iolabs/wip-dialog";
import {
    createStyles,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Theme,
    IconButton,
} from "@material-ui/core";
import clsx from "clsx";
import React, { ReactNode, useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { defaultDrawerWidth } from "../Layout/PrivateLayout";

type StylesProps = {
    menuColor?: string;
    menuActiveColor?: string;
    dividerColor?: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: `${defaultDrawerWidth}px`,
        },
        listItem: (props: StylesProps | undefined) => ({
            color: props?.menuColor ? props?.menuColor : theme.palette.text.primary,
            paddingLeft: theme.spacing(1.8),
            paddingRight: theme.spacing(1),
        }),
        listItemActive: (props: StylesProps | undefined) => ({
            "& .MuiListItemText-root": {
                color: props?.menuActiveColor ? props?.menuActiveColor : theme.palette.primary.dark,
            },
            "& .MuiListItemIcon-root": {
                color: props?.menuActiveColor ? props?.menuActiveColor : theme.palette.primary.dark,
            },
        }),
        listItemIcon: {
            minWidth: "38px",
        },
        listItemText: {
            fontSize: "1rem",
            paddingLeft: theme.spacing(1),
            whiteSpace: "normal",
            "& span": {
                lineHeight: "1.2rem",
            },
        },
        drawerControls: {
            paddingLeft: "1rem !important",
            display: "flex",
            justifyContent: "space-between",
            [theme.breakpoints.up("sm")]: {
                display: "none",
            },
        },
        drawerOpen: {
            visibility: "visible",
        },
        drawerClose: {
            visibility: "hidden",
        },
        visible: {
            visibility: "visible",
        },
        hidden: {
            visibility: "hidden",
        },
        collapseButton: {
            color: theme.palette.grey[500],
        },
        nested: {
            paddingLeft: theme.spacing(5),
        },
        divider: (props: StylesProps | undefined) => ({
            backgroundColor: props?.dividerColor ? props?.dividerColor : theme.palette.primary.light,
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
        }),
    })
);

export type IMenuItem = {
    title: string;
    path: string;
    icon: ReactNode;
    wip?: false;
    children?: IMenuItem[];
};

interface IPrivateMenuProps extends React.HTMLAttributes<HTMLElement> {
    drawerOpen: boolean;
    handleDrawerOpen: () => void;
    handleDrawerClose: () => void;
    menuItems: IMenuItem[];
    stylesProps?: StylesProps;
}

const PrivateMenu: React.FC<IPrivateMenuProps> = (props: IPrivateMenuProps) => {
    const { drawerOpen, handleDrawerOpen, handleDrawerClose, menuItems, stylesProps } = props;

    const classes = useStyles(stylesProps);
    const location = useLocation();
    const [content, setContent] = useState<ReactNode | null>(null);
    const { setGlobalDialogOpen } = useGlobalDialog();

    useEffect(() => {
        setContent(
            menuItems?.map((item: IMenuItem, index: number) => {
                return item.wip ? (
                    <ListItem key={index} button className={classes.listItem} onClick={() => setGlobalDialogOpen(true)}>
                        <ListItemIcon className={classes.listItemIcon}>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.title} className={classes.listItemText} />
                    </ListItem>
                ) : (
                    <ListItem
                        key={index}
                        button
                        component={RouterLink}
                        to={item.path}
                        className={clsx(classes.listItem, {
                            [classes.listItemActive]: isMenuItemActive(location.pathname, item.path),
                        })}
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            {item.icon}

                            {/*<Icon*/}
                            {/*    name="long-arrow-left"*/}
                            {/*    fill={theme.palette.common.white}*/}
                            {/*    size={25}*/}
                            {/*    className={classes.menuIcon}*/}
                            {/*/>*/}
                        </ListItemIcon>
                        <ListItemText primary={item.title} className={classes.listItemText} />
                    </ListItem>
                );
            })
        );
    }, [setContent, location, classes]);

    return (
        <List component="nav" aria-labelledby="menu" className={classes.root}>
            <ListItem className={clsx(classes.listItem, classes.drawerControls)}>
                <IconButton
                    aria-label="open"
                    className={clsx(classes.drawerOpen, {
                        [classes.visible]: !drawerOpen,
                        [classes.hidden]: drawerOpen,
                    })}
                >
                    <ChevronRightIcon onClick={handleDrawerOpen} />
                </IconButton>
                <IconButton
                    aria-label="close"
                    className={clsx(classes.drawerClose, {
                        [classes.visible]: drawerOpen,
                        [classes.hidden]: !drawerOpen,
                    })}
                >
                    <ChevronLeftIcon onClick={handleDrawerClose} />
                </IconButton>
            </ListItem>

            {content}
        </List>
    );
};

export default PrivateMenu;
