import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { Checkboxes, Select, TextField } from "mui-rff";
import { Button, Grid, MenuItem } from "@material-ui/core";
import { getStorages } from "../../api/client";
import { Storage } from "../../api/types";

interface Props {
    onSubmit: (values) => void;
    initialValues: any;
}

export const MainJobForm: React.FC<Props> = ({ onSubmit, initialValues }) => {
    const [storages, setStorages] = useState<Storage[]>([]);

    useEffect(() => {
        getStorages().then(s => setStorages(s));
    }, []);

    return (
        <Form onSubmit={onSubmit} initialValues={initialValues}>
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <Select label="Master" name="master">
                        {storages.map((s: Storage) => (
                            <MenuItem key={s.id} value={s.id}>
                                {s.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <Select label="Slave" name="slave">
                        {storages.map((s: Storage) => (
                            <MenuItem key={s.id} value={s.id}>
                                {s.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextField label="Name" name="name" required={true} />
                    <TextField label="Include" name="include" required={true} />
                    <TextField label="Exclude" name="exclude" />
                    <TextField label="Expression" name="expression" />
                    <TextField label="Note" name="note" />
                    <Checkboxes
                        name="cronActive"
                        data={{
                            label: "Scheduled",
                            value: true,
                        }}
                    />
                    <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                        <Button type="submit">Save</Button>
                    </Grid>
                </form>
            )}
        </Form>
    );
};
