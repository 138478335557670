import { createStyles, IconButton, Theme } from "@material-ui/core";
import clsx from "clsx";
import ForwardSharpIcon from "@material-ui/icons/ForwardSharp";
import SyncIcon from "@material-ui/icons/Sync";
import React, { FC } from "react";
import { makeStyles } from "@material-ui/styles";
import { useField } from "react-final-form";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dirButton: {
            "& svg": {
                fill: theme.palette.grey["300"],
            },
        },
        dirButtonActive: {
            "& svg": {
                fill: theme.palette.success.main,
            },
        },
    })
);

interface Props {
    name: string;
}

export const SyncSlaveRadio: FC<Props> = ({ name }) => {
    const classes = useStyles();
    const { input } = useField(name);

    return (
        <>
            <IconButton
                aria-label="right"
                className={clsx(classes.dirButton, {
                    [classes.dirButtonActive]: !input.value,
                })}
                onClick={() => input.onChange(false)}
            >
                <ForwardSharpIcon fontSize="large" />
            </IconButton>
            <IconButton
                aria-label="both"
                className={clsx(classes.dirButton, {
                    [classes.dirButtonActive]: !!input.value,
                })}
                onClick={() => input.onChange(true)}
            >
                <SyncIcon fontSize="large" style={{ transform: "scaleX(-1) rotate(-45deg)" }} />
            </IconButton>
        </>
    );
};
