import { IModule } from "redux-dynamic-modules";
import { ILayoutModuleState, reducer } from "./reducer";
import middlewares from "./middleware";

export const LayoutModule: IModule<ILayoutModuleState> = {
    id: "layout-module",
    reducerMap: {
        layoutState: reducer,
    },
    middlewares: middlewares,
};
