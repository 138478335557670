import { Box, Container, createStyles, Grid, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import imgConnection from "../../../assets/images/connection.png";
import imgShape from "../../../assets/images/shape.svg";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            background: `url(${imgConnection}) no-repeat`,
            backgroundSize: "80%",
            backgroundPosition: "80% 100%",
            [theme.breakpoints.up("sm")]: {
                backgroundSize: "70%",
                backgroundPosition: "90% 100%",
            },
            [theme.breakpoints.up("md")]: {
                backgroundSize: "60%",
                backgroundPosition: "90% 80%",
            },
            [theme.breakpoints.up("lg")]: {
                backgroundSize: "60%",
                backgroundPosition: "90% 70%",
            },
        },
        container: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: theme.spacing(4),
            [theme.breakpoints.up("md")]: {
                paddingTop: theme.spacing(10),
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
        },
        gridHeading: {},
        gridSubtitle: {
            marginTop: theme.spacing(2),
        },
        imgShape: {
            position: "absolute",
            left: 0,
            top: 86,
            bottom: 0,
            margin: "auto",
            zIndex: -1,
            opacity: 0.5,
            width: 300,
            [theme.breakpoints.up("lg")]: {
                opacity: 1,
                width: "20%",
            },
        },
    })
);

const HomePage: React.FC = () => {
    const classes = useStyles();
    // const dispatch = useDispatch<DispatchAction>();

    // translations
    // const intl = useIntl();
    // const transPageTitle = intl.formatMessage({ ...messages.pageTitle });

    return (
        <Box className={classes.root}>
            <Container maxWidth="md" className={classes.container}>
                <Grid container className={classes.gridHeading}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Typography variant="h1">Connect BIM360 and&nbsp;SharePoint in&nbsp;minutes</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className={classes.gridSubtitle}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Typography variant="subtitle1">
                            Easy automation for busy people. Sync Tool moves info between your BIM360 and SharePoint App
                            automatically, so you can focus on your most important work.
                        </Typography>
                    </Grid>
                </Grid>

                <img src={imgShape} alt="Shape" className={classes.imgShape} />
            </Container>
        </Box>
    );
};

export default HomePage;
