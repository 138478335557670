import { ApiClient } from "@iolabs/api-client";
import {
    ForgeHook,
    MainJob,
    Job,
    Storage,
    MainJobRequest,
    SharePointHook,
    TreeNode,
    StorageType,
    PlatformConfig,
    Node,
    SyncLog,
    SyncLogQuery,
} from "./types";

let client = null;
const getClient = () => (client ? client : (client = new ApiClient("")));

export const getMainJobs = async (): Promise<MainJob[]> => {
    try {
        return (await getClient().get("/main-job")).data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const getMainJob = async (id: number): Promise<MainJob> => {
    return (await getClient().get(`/main-job/${id}`)).data;
};

export const createMainJob = async (data: MainJobRequest): Promise<any> => {
    try {
        return (await getClient().post("/main-job", data)).data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const editMainJob = async (id: number, data: MainJobRequest): Promise<any> => {
    try {
        return (await getClient().put(`/main-job/${id}`, data)).data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const activeMainJob = async (id: number, active: boolean): Promise<any> => {
    try {
        return (await getClient().put(`/main-job/${id}/${active ? "active" : "inactive"}`)).data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const deleteMainJob = async (id: number): Promise<any> => {
    try {
        return (await getClient().delete(`/main-job/${id}`)).data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const runMainJob = async (id: number): Promise<any> => {
    try {
        return (await getClient().post(`/main-job/${id}/run`)).data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const getJobs = async (): Promise<Job[]> => {
    try {
        return (await getClient().get("/job")).data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const cancelJob = async (id: number): Promise<any> => {
    try {
        return (await getClient().delete(`/job/${id}`)).data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

// sync log
export const getSyncLogs = async (filter: SyncLogQuery): Promise<SyncLog[]> => {
    try {
        const str: string[] = [];
        Object.entries(filter).forEach(([name, value]) => {
            if (value !== undefined && value !== "") {
                str.push(`${name}=${value}`);
            }
        });

        return (await getClient().get("/sync-log?" + str.join("&"))).data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

// management

export const getStorages = async (): Promise<Storage[]> => {
    try {
        return (await getClient().get("/storage")).data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const removeStorage = async (id: number): Promise<any> => {
    try {
        await getClient().delete(`/storage/${id}`);
    } catch (error) {
        console.error(error);
    }
};

export const getForgeHooks = async (): Promise<ForgeHook[]> => {
    try {
        return (await getClient().get("/management/forge/hook")).data.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const createForgeHook = async (storageId: number): Promise<any> => {
    try {
        return (await getClient().post(`/management/forge/hook/${storageId}`)).data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const deleteForgeHook = async (hookId: string, event: string): Promise<any> => {
    try {
        await getClient().delete(`/management/forge/hook/${hookId}/${event}`);
    } catch (error) {
        console.error(error);
    }
};

export const deleteForgeHooks = async (): Promise<any> => {
    try {
        await getClient().delete("/management/forge/hook/all");
    } catch (error) {
        console.error(error);
    }
};

export const getSharePointHooks = async (): Promise<SharePointHook[]> => {
    try {
        return (await getClient().get("/management/share-point/hook")).data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const createSharePointHook = async (storageId: number): Promise<any> => {
    try {
        return (await getClient().post(`/management/share-point/hook/${storageId}`)).data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const deleteSharePointHook = async (hookId: string): Promise<any> => {
    try {
        await getClient().delete(`/management/share-point/hook/${hookId}`);
    } catch (error) {
        console.error(error);
    }
};

export const getNodes = async (): Promise<Node[]> => {
    try {
        return (await getClient().get("/management/nodes")).data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const syncNodes = async (): Promise<any> => {
    try {
        await getClient().post("/management/nodes/sync");
    } catch (error) {
        console.error(error);
    }
};

// platform
export const getPlatformConfigs = async (): Promise<PlatformConfig[]> => {
    try {
        return (await getClient().get("/platform")).data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const updatePlatformConfig = async (id: number, clientId: string, clientSecret: string): Promise<any> => {
    try {
        await getClient().put(`/platform/${id}`, { clientId, clientSecret });
    } catch (error) {
        console.error(error);
    }
};

// tree
export const getTree = async (type: StorageType, nodeId: string | null, depth = 1): Promise<TreeNode[]> => {
    try {
        const t = type === StorageType.Forge ? "forge" : "sharepoint";
        return (await getClient().get(`/datamanagement/${t}/tree?depth=${depth}${nodeId ? `&nodeId=${nodeId}` : ""}`))
            .data;
    } catch (error) {
        console.error(error);
        return [];
    }
};
