import { createStyles, Grid, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { cancelJob, getJobs } from "../../../api/client";
import { Job } from "../../../api/types";
import { Column, MagicTable } from "../../../components/Table/MagicTable";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerContainer: {
            minHeight: "25vh",
            alignItems: "stretch",
        },
        projectContainer: {},
        textGrid: {
            padding: theme.spacing(2),
            "& p": {
                fontSize: "12px",
            },
        },
        projectsGrid: {
            padding: theme.spacing(2),
            paddingTop: 0,
            marginBottom: "4rem",
            [theme.breakpoints.up("md")]: {
                marginBottom: "1rem",
            },
        },
        title: {
            color: theme.palette.grey[700],
        },
        subtitle: {
            marginTop: theme.spacing(1),
            fontSize: "14px",
        },
    })
);

const jobColumns: Column<Job>[] = [
    {
        name: "id",
        label: "ID",
        align: "right",
    },
    {
        name: "include",
        label: "Include",
    },
    {
        name: "exclude",
        label: "Exclude",
    },
    {
        name: null,
        label: "Master",
        renderer: row => <>{row.master.name}</>,
    },
    {
        name: null,
        label: "Master path",
        renderer: row => <>{row.master.config.RootPath}</>,
    },
    {
        name: null,
        label: "Slave",
        renderer: row => <>{row.slave.name}</>,
    },
    {
        name: null,
        label: "Slave path",
        renderer: row => <>{row.slave.config.RootPath}</>,
    },
    {
        name: "createdAt",
        label: "Created",
    },
    {
        name: "startedAt",
        label: "Started",
    },
    {
        name: "finishedAt",
        label: "Finished",
    },
    {
        name: "cancelled",
        label: "Cancelled",
    },

    {
        name: null,
        label: "",
        renderer: row => (
            <>
                {!row.cancelled && !row.finishedAt && (
                    <Button onClick={() => cancelJob(row.id).then(_ => window.location.reload())}>Cancel</Button>
                )}
            </>
        ),
    },
];

const HistoryPage: React.FC = () => {
    const classes = useStyles();

    // table data
    const [rows, setRows] = useState<Job[] | null>(null);

    const loadRows = useCallback(async () => {
        setRows(await getJobs());
    }, [setRows]);

    useEffect(() => {
        loadRows();
    }, []);

    return (
        <Switch>
            <Route
                render={() => (
                    <>
                        <Grid container spacing={0} className={classes.headerContainer}>
                            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.textGrid}>
                                <MagicTable columns={jobColumns} rows={rows} />
                            </Grid>
                        </Grid>
                    </>
                )}
            />
        </Switch>
    );
};

export default HistoryPage;
