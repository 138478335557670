import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import React, { ReactElement } from "react";

export interface Column<T> {
    name: keyof T | null;
    label: string;
    align?: "inherit" | "left" | "center" | "right" | "justify";
    renderer?: (row: T) => ReactElement;
}

interface Props<T> {
    columns: Column<T>[];
    rows: T[] | null;
}

export const MagicTable = <T extends {}>({ columns, rows }: Props<T>) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map(({ label, align }, i) => (
                            <TableCell key={i} align={align || "left"}>
                                {label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows !== null &&
                        rows.map((row, i) => (
                            <TableRow key={i}>
                                {columns.map(({ name, renderer, align }, j) => (
                                    <TableCell key={j} align={align || "left"}>
                                        {renderer !== undefined ? renderer(row) : name !== null ? row[name] : ""}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    {(rows === null || rows.length === 0) && (
                        <TableRow>
                            <TableCell colSpan={columns.length} align="center">
                                {rows === null ? "Loading..." : "No items"}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
