import { defineMessages } from "react-intl";

export default defineMessages({
    pageTitle: {
        id: "pages__error-page__page-title",
        defaultMessage: "Ooops, page not found!",
    },
    back: {
        id: "pages__error-page__back-button",
        defaultMessage: "Back to homepage",
    },
});
