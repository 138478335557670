import React, { Component } from "react";
import { useKeycloak } from "react-keycloak";
import { Redirect, Route } from "react-router-dom";

export function PrivateRoute({ component: Component, ...rest }) {
    const { keycloak } = useKeycloak();

    return (
        <Route
            {...rest}
            render={props =>
                keycloak?.authenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: rest.loginPath,
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}
