import { ActionTypes } from "./action";
import { AnyAction } from "redux";

export interface ILayoutModuleState {
    layoutState: ILayoutState;
}

export enum Hint {
    masterPlatform = 0,
    masterProject = 1,
    masterFolders = 2,
    slavePlatform = 3,
    slaveProject = 4,
    slaveFolders = 5,
    mappingName = 6,
    mappingOptions = 7,
    mappingDirection = 8,
    mappingIncludes = 9,
    mappingExcludes = 10,
}

export interface ILayoutState {
    hints: Hint[];
}

export const initialState: Readonly<ILayoutState> = {
    hints: [Hint.masterPlatform],
};

export function reducer(state: ILayoutState = initialState, action: AnyAction): ILayoutState {
    switch (action.type) {
        case ActionTypes.OnHints: {
            const { hints } = action.payload;
            return {
                ...state,
                hints,
            };
        }

        default:
            return state;
    }
}
