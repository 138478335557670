import { defineMessages } from "react-intl";

export default defineMessages({
    company: {
        id: "components__header__company",
        defaultMessage: "Grimm Bauen und Verputzen GmbH",
    },
    notifications: {
        id: "components__header__notifications",
        defaultMessage: "Notifications",
    },
    search: {
        id: "components__header__search",
        defaultMessage: "Search",
    },
    settings: {
        id: "components__header__settings",
        defaultMessage: "Settings",
    },
    help: {
        id: "components__header__help",
        defaultMessage: "Help",
    },
    signOut: {
        id: "components__header__sign-out",
        defaultMessage: "Sign out",
    },
});
