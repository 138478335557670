import { Box, Button, Container, createStyles, Paper, Theme, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/styles";
import { Form } from "react-final-form";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink as RouterLink, useParams } from "react-router-dom";
import { Path } from "../../PageRouter/PageRouter";
import { editMainJob, getMainJob } from "../../../api/client";
import { MappingBox } from "./MappingBox";
import clsx from "clsx";
import { MainJob } from "../../../api/types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            background: theme.palette.grey["100"],
            minHeight: "100%",
        },
        paper: {
            flexBasis: "33.33%",
            zIndex: 100,
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            [theme.breakpoints.up("md")]: {
                padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
            },
        },
        button: {
            textTransform: "inherit",
            paddingTop: theme.spacing(0.8),
            paddingBottom: theme.spacing(0.8),
        },
        platformBox: {
            display: "flex",
            flexFlow: "column nowrap",
            [theme.breakpoints.up("md")]: {
                flexFlow: "row nowrap",
            },
        },
    })
);

interface FormData {
    name: string;
    master: any;
    includes: { path: string }[];
    excludes: string[];
    repeat: string;
    time: number;
    syncSlave: boolean;
}

const EditMappingPage: React.FC = () => {
    const classes = useStyles();
    const { id } = useParams();
    const mainJobId = parseInt(id as string);

    const [mainJob, setMainJob] = useState<MainJob>();
    const [initialValues, setInitialValues] = useState<FormData>();

    const load = useCallback(async () => {
        let mj: MainJob;

        try {
            mj = await getMainJob(mainJobId);
        } catch (e) {
            // TODO fix
            mj = await getMainJob(mainJobId);
        }

        setMainJob(mj);
        setInitialValues({
            ...mj,
            includes: mj.includes.map(p => ({ path: p.replace(/(\/\*\*)+$/, "") })),
            excludes: mj.excludes?.map(p => p.replace(/^(\*\*\/\*)+/, "")) || [],
            master: {
                root: [
                    {
                        id: btoa(
                            JSON.stringify(
                                mj.master.type === "SharePoint"
                                    ? {
                                          Type: "folder",
                                          NodeId: (mj.master.config as any).Folder,
                                          ParentId: (mj.master.config as any).Drive,
                                      }
                                    : {
                                          Type: "folder",
                                          NodeId: (mj.master.config as any).Folder,
                                          ParentId: (mj.master.config as any).ProjectId,
                                      }
                            )
                        ),
                    },
                ],
                type: mj.master.type,
            },
        });
    }, [mainJobId]);

    useEffect(() => {
        load();
    }, []);

    return (
        <Container maxWidth={false} classes={{ root: classes.root }}>
            <Form
                onSubmit={(v: FormData) =>
                    editMainJob(mainJobId, {
                        name: v.name,
                        excludes: v.excludes,
                        includes: v.includes.map(n => n.path),
                        repeat: v.repeat || "daily",
                        time: v.time || 0,
                        syncSlave: v.syncSlave,
                    })
                }
                initialValues={initialValues}
            >
                {({ handleSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Box mt={1} mb={1} display="flex" justifyContent="flex-end">
                            <Button
                                variant="contained"
                                color="default"
                                className={classes.button}
                                startIcon={<ArrowBackIcon />}
                                component={RouterLink}
                                to={Path.MAPPINGS}
                            >
                                Cancel
                            </Button>
                        </Box>

                        <Box className={classes.platformBox}>
                            <Paper className={clsx(classes.paper)}>
                                <Typography>Master platform ({mainJob?.master.type})</Typography>
                                <br />
                                <strong>{mainJob?.master.config.RootPath}</strong>
                            </Paper>
                            <MappingBox active={true} values={values} />
                            <Paper className={clsx(classes.paper)}>
                                <Typography>Slave platform ({mainJob?.slave.type})</Typography>
                                <br />
                                <strong>{mainJob?.slave.config.RootPath}</strong>
                            </Paper>
                        </Box>
                    </form>
                )}
            </Form>
        </Container>
    );
};

export default EditMappingPage;
