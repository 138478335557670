import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";

type StylesProps = {
    color?: string;
    backgroundColor?: string;
    dividerColor?: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: (props: StylesProps | undefined) => ({
            display: "inline-block",
            fontSize: "0.8rem",
            color: props?.color ? props?.color : theme.palette.common.white,
            backgroundColor: props?.backgroundColor ? props?.backgroundColor : theme.palette.secondary.main,
            borderRadius: "50px",
            padding: `${theme.spacing(0.25)} ${theme.spacing(0.75)}`,
            position: "absolute",
            zIndex: 10,
            right: 0,
            marginTop: "-23px",
            height: "22px",
        }),
    })
);

interface IHintProps extends React.HTMLAttributes<HTMLElement> {
    text: string;
    stylesProps?: StylesProps;
}

const Hinter: React.FC<IHintProps> = (props: IHintProps) => {
    const { text, stylesProps } = props;

    const classes = useStyles(stylesProps);

    return <Box className={classes.root}>{text}</Box>;
};

export default Hinter;
