import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { createMainJob, editMainJob } from "../../../api/client";
import { MainJobRequest } from "../../../api/types";
import { MainJobForm } from "../../../components/Form/MainJobForm";

interface Props {
    open: boolean;
    handleClose: () => void;
    id: number | null;
}

export const FormDialog: React.FC<Props> = ({ open, handleClose, id }) => {
    const [initialValues] = useState<MainJobRequest>();

    const onSubmit = useCallback(
        async (values: MainJobRequest) => {
            if (id != null) {
                await editMainJob(id, values);
            } else {
                await createMainJob(values);
            }
            window.location.reload();
        },
        [id]
    );

    /*
    useEffect(() => {
        if (id) {
            getMainJob(id).then(mainJob =>
                setInitialValues({
                    ...mainJob,
                    master: mainJob.master.id,
                    slave: mainJob.slave.id,
                })
            );
        } else {
        }
    }, [id]);
    */

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Main job</DialogTitle>
            <DialogContent>
                <MainJobForm onSubmit={onSubmit} initialValues={initialValues} />
            </DialogContent>
        </Dialog>
    );
};
