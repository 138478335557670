import { DispatchAction } from "@iolabs/redux-utils";
import { createStyles, FormControl, InputLabel, Paper, Theme, Tooltip, Typography } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import CheckboxTree from "../../../components/CheckboxTree/CheckboxTree";
import { PlatformSelect } from "../../../components/Form/PlatformSelect";
import { ProjectSelect } from "../../../components/Form/ProjectSelect";
import Hinter from "../../../components/Hinter/Hinter";
import { onHints, useHints } from "../../../redux/layout";
import { Hint } from "../../../redux/layout/reducer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            background: theme.palette.grey["100"],
            height: "100%",
        },
        button: {
            textTransform: "inherit",
            paddingTop: theme.spacing(0.8),
            paddingBottom: theme.spacing(0.8),
        },
        platformBox: {
            display: "flex",
            flexFlow: "column nowrap",
            [theme.breakpoints.up("md")]: {
                flexFlow: "row nowrap",
            },
        },
        paper: {
            zIndex: 100,
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            [theme.breakpoints.up("md")]: {
                padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
            },
        },
        masterPaper: {
            flexBasis: "33.33%",
        },
        mappingPaper: {
            [theme.breakpoints.up("md")]: {
                maxWidth: "350px",
                marginLeft: theme.spacing(4),
                marginRight: theme.spacing(4),
            },
            [theme.breakpoints.up("lg")]: {
                marginLeft: theme.spacing(8),
                marginRight: theme.spacing(8),
            },
        },
        slavePaper: {
            flexBasis: "33.33%",
        },
        formControl: {
            marginTop: "50px",
            marginBottom: theme.spacing(1),
            "& .MuiFormLabel-root.Mui-focused": {
                color: theme.palette.common.black,
            },
        },
        label: {
            top: "-40px",
            left: "-14px",
            color: theme.palette.common.black,
            pointerEvents: "auto",
            "& span": {
                fontWeight: 700,
                lineHeight: 1,
                textTransform: "none",
                letterSpacing: 0,
            },
        },
        labelMapping: {
            top: "-30px",
        },
        input: {
            pointerEvents: "auto",
            backgroundColor: theme.palette.primary["100"],
        },
        notchedOutline: {
            borderColor: theme.palette.primary["100"],
        },
        autocomplete: {
            "& .MuiFormControl-marginDense": {
                marginTop: 0,
            },
        },
        helpIcon: {
            fontSize: "1.5rem",
            fill: "rgba(122, 122, 122, 0.26)",
            margin: "4px 0 0 2px",
            cursor: "help",
        },
        buttons: {
            marginTop: theme.spacing(4),
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            "& button": {
                textTransform: "none",
                flexBasis: "50%",
                "&:nth-child(1)": {
                    marginRight: theme.spacing(1),
                },
                "&:nth-child(2)": {
                    marginLeft: theme.spacing(1),
                },
            },
        },
    })
);

interface Props {
    master: boolean;
    values: any;
}

export const StorageBox: FC<Props> = ({ master, values }) => {
    const classes = useStyles();
    const prefix = master ? "master" : "slave";
    const hints: Hint[] = useHints();
    const dispatch = useDispatch<DispatchAction>();

    // handle hints states for hinter component
    useEffect(() => {
        if (master) {
            // master platform selected
            if (values?.master?.type && hints.includes(Hint.masterPlatform)) {
                dispatch(onHints({ hints: [Hint.masterProject] }));
            }
            // master project selected
            if (values?.master?.project && hints.includes(Hint.masterProject)) {
                dispatch(onHints({ hints: [Hint.masterFolders] }));
            }
            // master folder selected
            if (values?.master?.root?.length > 0 && hints.includes(Hint.masterFolders)) {
                dispatch(onHints({ hints: [Hint.slavePlatform] }));
            }
        } else {
            // slave platform selected
            if (values?.slave?.type && hints.includes(Hint.slavePlatform)) {
                dispatch(onHints({ hints: [Hint.slaveProject] }));
            }
            // slave project selected
            if (values?.slave?.project && hints.includes(Hint.slaveProject)) {
                dispatch(onHints({ hints: [Hint.slaveFolders] }));
            }
            // slave folder selected
            if (values?.slave?.root?.length > 0 && hints.includes(Hint.slaveFolders)) {
                dispatch(onHints({ hints: [Hint.mappingName] }));
            }
        }
    }, [values]);

    return (
        <Paper className={clsx(classes.paper, master ? classes.masterPaper : classes.slavePaper)}>
            <Typography>Choose {prefix} platform</Typography>

            <FormControl fullWidth variant="outlined" size="small" margin="dense" className={classes.formControl}>
                <InputLabel shrink htmlFor="master-platform" className={classes.label}>
                    <Typography variant="overline">
                        Your platform
                        <Tooltip title="Help text for your platform" placement="top">
                            <HelpIcon className={classes.helpIcon} />
                        </Tooltip>
                    </Typography>
                </InputLabel>

                {hints.includes(master ? Hint.masterPlatform : Hint.slavePlatform) && <Hinter text="Select platform" />}

                <PlatformSelect name={`${prefix}.type`} />
            </FormControl>

            <FormControl fullWidth variant="outlined" size="small" margin="dense" className={classes.formControl}>
                <InputLabel shrink htmlFor="master-project" className={classes.label}>
                    <Typography variant="overline">
                        Choose your project
                        <Tooltip title="Help text for your project" placement="top">
                            <HelpIcon className={classes.helpIcon} />
                        </Tooltip>
                    </Typography>
                </InputLabel>

                {hints.includes(master ? Hint.masterProject : Hint.slaveProject) && <Hinter text="Select project" />}

                <ProjectSelect name={`${prefix}.project`} platform={values[prefix]?.type} />
            </FormControl>

            <FormControl fullWidth variant="outlined" size="small" margin="dense" className={classes.formControl}>
                <InputLabel shrink htmlFor="master-folder" className={classes.label}>
                    <Typography variant="overline">
                        Select your {prefix} root folder
                        <Tooltip title="Help text for your folders" placement="top">
                            <HelpIcon className={classes.helpIcon} />
                        </Tooltip>
                    </Typography>
                </InputLabel>

                {hints.includes(master ? Hint.masterFolders : Hint.slaveFolders) && (
                    <Hinter text="Do you want to specify folders?" />
                )}

                <CheckboxTree
                    name={`${prefix}.root`}
                    rootMode={true}
                    platform={values[prefix]?.type}
                    parentId={values[prefix]?.project}
                />
            </FormControl>
        </Paper>
    );
};
