import {
    Box,
    Button,
    Checkbox,
    createStyles,
    ExpansionPanel,
    ExpansionPanelActions,
    ExpansionPanelSummary,
    FormControlLabel,
    Switch,
    Theme,
    Typography,
    withStyles,
} from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { NavLink as RouterLink } from "react-router-dom";
import { activeMainJob, deleteMainJob, getMainJobs, runMainJob } from "../../../api/client";
import { MainJob, Storage } from "../../../api/types";
import imgBim360 from "../../../assets/images/logo-bim360.png";
import imgSharepoint from "../../../assets/images/logo-sharepoint.png";
import PrivateContainer from "../../../components/Layout/PrivateContainer";
import theme from "../../../utils/Theming";
import { Path } from "../../PageRouter/PageRouter";
import messages from "./messages";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginRight: theme.spacing(2),
            textTransform: "inherit",
            paddingTop: theme.spacing(0.8),
            paddingBottom: theme.spacing(0.8),
        },
        panelBox: {
            width: "100%",
            overflowX: "auto",
        },
        panel: {
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "flex-start",
            minWidth: theme.breakpoints.width("sm"),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            background: "transparent",
            "& .MuiExpansionPanelSummary-root.Mui-expanded": {
                minHeight: "48px",
            },
            "& .MuiExpansionPanel-root.Mui-expanded": {
                margin: "auto",
            },
        },
        checkbox: {
            marginTop: theme.spacing(0.2),
            marginLeft: theme.spacing(1),
        },
        checkboxFake: {
            width: "42px",
            marginRight: theme.spacing(3),
        },
        expansionPanelHeader: {
            height: "30px",
        },
        expansionPanel: {
            background: "rgba(255, 255, 255, 0.8)",
            marginLeft: "-0.6rem !important",
            width: "100%",
            borderRadius: theme.shape.borderRadius,
            "&:before": {
                display: "none",
            },
            "& #panel-summary": {
                borderTop: `1px solid ${theme.palette.grey["200"]}`,
                "& > div": {
                    padding: "1px 8px",
                },
            },
        },
        panelSummary: {
            "& .MuiExpansionPanelSummary-content": {
                margin: 0,
                alignItems: "center",
            },
        },
        panelHeader: {
            color: theme.palette.grey["400"],
            textAlign: "left",
            flexBasis: "33.33%",
        },
        panelColumn: {
            flexBasis: "33.33%",
        },
        panelButton: {
            marginLeft: `${theme.spacing(2)} !important`,
            textTransform: "inherit",
        },
    })
);

const StatusSwitch = withStyles({
    switchBase: {
        color: theme.palette.common.white,
        "&$checked": {
            color: theme.palette.success.main,
        },
        "&$checked + $track": {
            backgroundColor: theme.palette.success.main,
        },
    },
    checked: {},
    track: {},
})(Switch);

const Logo: FC<{ storage: Storage; height?: number }> = ({ storage, height }) => {
    switch (storage.type) {
        case "Forge":
            return (
                <img
                    style={{ float: "left", paddingRight: "10px" }}
                    src={imgBim360}
                    alt="BIM360"
                    height={height || 20}
                />
            );
        case "SharePoint":
            return (
                <img
                    style={{ float: "left", paddingRight: "10px" }}
                    src={imgSharepoint}
                    alt="SharePoint"
                    height={height || 20}
                />
            );
        default:
            return <></>;
    }
};

const MappingPage: React.FC = () => {
    const classes = useStyles();

    // table data
    const [rows, setRows] = useState<MainJob[] | null>(null);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    const loadRows = useCallback(async () => {
        setRows(await getMainJobs());
    }, [setRows]);

    useEffect(() => {
        loadRows();
    }, []);

    const handleChangeStatus = (mainJob: MainJob, active: boolean) => {
        activeMainJob(mainJob.id, active).then(() => loadRows());
    };

    const handleCheckbox = (id: number, checked: boolean) => {
        if (checked) {
            setSelectedRows([...selectedRows, id]);
        } else {
            setSelectedRows([...selectedRows.filter(i => i !== id)]);
        }
    };

    const handleRunMainJob = (job: MainJob) => {
        runMainJob(job.id).then(_ => alert("Main job has been added to the queue."));
    };

    const handleDeleteMainJob = (job: MainJob) => {
        window.confirm("Do you want really delete this job?") &&
            deleteMainJob(job.id).then(_ => {
                loadRows();
            });
    };

    const handleBulkDelete = async () => {
        window.confirm("Do you want really delete all selected jobs?") &&
            (await Promise.all(selectedRows.map(async id => await deleteMainJob(id))).then(_ => {
                loadRows();
            }));
    };

    // translations
    const intl = useIntl();
    const transPageTitle = intl.formatMessage({ ...messages.pageTitle });

    return (
        <PrivateContainer stylesProps={{ bgConnection: true }}>
            <Typography variant="h4">{transPageTitle}</Typography>

            <Box mt={2} mb={2}>
                <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    startIcon={<DeleteForeverOutlinedIcon />}
                    onClick={handleBulkDelete}
                    disabled={selectedRows?.length === 0}
                >
                    Delete
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<AddOutlinedIcon />}
                    component={RouterLink}
                    to={Path.MAPPINGS_CREATE}
                >
                    Create
                </Button>
            </Box>

            <Box className={classes.panelBox}>
                <Box className={classes.panel}>
                    <Box className={classes.checkboxFake} />
                    <ExpansionPanel
                        square={false}
                        className={clsx(classes.expansionPanel, classes.expansionPanelHeader)}
                        elevation={0}
                    >
                        <ExpansionPanelSummary
                            expandIcon={<Box ml={1.1} mr={1.1} />}
                            aria-controls="panel-summary"
                            className={classes.panelSummary}
                        >
                            <div className={classes.panelHeader}>
                                <Typography variant="body2">
                                    <strong>Name</strong>
                                </Typography>
                            </div>
                            <div className={classes.panelHeader}>
                                <Typography variant="body2">
                                    <strong>Master</strong>
                                </Typography>
                            </div>
                            <div className={classes.panelHeader}>
                                <Typography variant="body2">
                                    <strong>Slave</strong>
                                </Typography>
                            </div>
                            <div className={classes.panelHeader}>
                                <Typography variant="body2">
                                    <strong>Status</strong>
                                </Typography>
                            </div>
                        </ExpansionPanelSummary>
                    </ExpansionPanel>
                </Box>

                {rows &&
                    rows.map((item, index) => (
                        <Box className={classes.panel} key={index}>
                            <FormControlLabel
                                aria-label="Checkbox"
                                onClick={event => event.stopPropagation()}
                                onFocus={event => event.stopPropagation()}
                                onChange={event => handleCheckbox(item.id, (event.target as any).checked)}
                                value={selectedRows.includes(item.id)}
                                control={<Checkbox color="primary" />}
                                label=""
                                className={classes.checkbox}
                            />
                            <ExpansionPanel key={item.id} square={false} className={classes.expansionPanel}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel-summary"
                                    className={classes.panelSummary}
                                >
                                    <div className={classes.panelColumn}>
                                        <Typography variant="body2">
                                            <strong>{item.name}</strong>
                                        </Typography>
                                    </div>
                                    <div className={classes.panelColumn}>
                                        <Logo storage={item.master} />
                                        <Typography variant="body2">{item.master.name}</Typography>
                                    </div>
                                    <div className={classes.panelColumn}>
                                        <Logo storage={item.slave} />
                                        <Typography variant="body2">{item.slave.name}</Typography>
                                    </div>
                                    <div className={classes.panelColumn}>
                                        <StatusSwitch
                                            checked={item.active}
                                            onClick={event => event.stopPropagation()}
                                            onFocus={event => event.stopPropagation()}
                                            onChange={(mainJob, active) => handleChangeStatus(item, active)}
                                            color="default"
                                            name={item.name}
                                            inputProps={{ "aria-label": "status" }}
                                        />
                                    </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelActions>
                                    <ExpansionPanelActions>
                                        <Button
                                            color="default"
                                            className={classes.panelButton}
                                            startIcon={<PlayArrowIcon />}
                                            onClick={() => handleRunMainJob(item)}
                                        >
                                            Run
                                        </Button>
                                        <Button
                                            color="default"
                                            className={classes.panelButton}
                                            startIcon={<DeleteForeverOutlinedIcon />}
                                            onClick={() => handleDeleteMainJob(item)}
                                        >
                                            Delete
                                        </Button>
                                        <Button
                                            color="default"
                                            className={classes.panelButton}
                                            startIcon={<BorderColorIcon />}
                                            component={RouterLink}
                                            to={Path.MAPPINGS_EDIT + item.id}
                                        >
                                            Edit
                                        </Button>
                                    </ExpansionPanelActions>
                                </ExpansionPanelActions>
                            </ExpansionPanel>
                        </Box>
                    ))}
            </Box>
        </PrivateContainer>
    );
};

export default MappingPage;
