import { createStyles, Grid, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import {
    createForgeHook,
    createSharePointHook,
    deleteForgeHook,
    deleteForgeHooks,
    deleteSharePointHook,
    getForgeHooks,
    getNodes,
    getSharePointHooks,
    getStorages,
    removeStorage,
    syncNodes,
} from "../../../api/client";
import { ForgeHook, Node, SharePointHook, Storage } from "../../../api/types";
import { MagicTable, Column } from "../../../components/Table/MagicTable";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerContainer: {
            minHeight: "25vh",
            alignItems: "stretch",
        },
        projectContainer: {},
        textGrid: {
            padding: theme.spacing(2),
            "& p": {
                fontSize: "12px",
            },
        },
        projectsGrid: {
            padding: theme.spacing(2),
            paddingTop: 0,
            marginBottom: "4rem",
            [theme.breakpoints.up("md")]: {
                marginBottom: "1rem",
            },
        },
        title: {
            color: theme.palette.grey[700],
        },
        subtitle: {
            marginTop: theme.spacing(1),
            fontSize: "14px",
        },
    })
);

const forgeHookColumns: Column<ForgeHook>[] = [
    {
        name: "hookId",
        label: "Hook ID",
        align: "right",
    },
    {
        name: "event",
        label: "Event",
    },
    {
        name: "callbackUrl",
        label: "Callback URL",
    },
    {
        name: null,
        label: "",
        align: "right",
        renderer: ({ hookId, event }: ForgeHook) => (
            <Button onClick={() => deleteForgeHook(hookId, event)}>Delete</Button>
        ),
    },
];

const sharePointHookColumns: Column<SharePointHook>[] = [
    {
        name: "id",
        label: "Hook ID",
        align: "right",
    },
    {
        name: "expirationDateTime",
        label: "Expiration",
    },
    {
        name: "notificationUrl",
        label: "Notification URL",
    },
    {
        name: null,
        label: "",
        align: "right",
        renderer: ({ id }: SharePointHook) => (
            <Button onClick={() => deleteSharePointHook(id).then(_ => window.location.reload())}>Delete</Button>
        ),
    },
];

const storageColumns: Column<Storage>[] = [
    {
        name: "id",
        label: "ID",
        align: "right",
    },
    {
        name: "name",
        label: "Name",
    },
    {
        name: null,
        label: "",
        align: "right",
        renderer: storage => <Button onClick={() => removeStorage(storage.id)}>Delete</Button>,
    },
    {
        name: null,
        label: "",
        align: "right",
        renderer: (storage: Storage) => {
            switch (storage.type) {
                case "Forge":
                    return (
                        <Button onClick={() => createForgeHook(storage.id).then(_ => window.location.reload())}>
                            Add hook
                        </Button>
                    );
                case "SharePoint":
                    return (
                        <Button onClick={() => createSharePointHook(storage.id).then(_ => window.location.reload())}>
                            Add hook
                        </Button>
                    );
                default:
                    return <>?</>;
            }
        },
    },
];

const nodeColumns: Column<Node>[] = [
    {
        name: "id",
        label: "ID",
        align: "right",
    },
    {
        name: "storage",
        label: "Storage",
        renderer: row => (
            <span title={row.storage.config}>
                {row.storage.name} ({row.storage.id})
            </span>
        ),
    },
    {
        name: "nodeId",
        label: "Node ID",
    },
    {
        name: "path",
        label: "Path",
    },
    {
        name: "folder",
        label: "Folder",
        renderer: row => <>{row.folder ? "Y" : "N"}</>,
    },
    {
        name: "updatedAt",
        label: "Updated at",
    },
];

const ManagementPage: React.FC = () => {
    const classes = useStyles();

    // table data
    const [forgeHookRows, setForgeHookRows] = useState<ForgeHook[] | null>(null);
    const [sharePointHookRows, setSharePointHookRows] = useState<SharePointHook[] | null>(null);
    const [storageRows, setStorageRows] = useState<Storage[] | null>(null);
    const [nodeRows, setNodeRows] = useState<Node[] | null>(null);

    const loadRows = useCallback(async () => {
        setForgeHookRows(await getForgeHooks());
        setSharePointHookRows(await getSharePointHooks());
        setStorageRows(await getStorages());
        setNodeRows(await getNodes());
    }, []);

    useEffect(() => {
        loadRows();
    }, []);

    return (
        <Switch>
            <Route
                render={() => (
                    <>
                        <Grid container spacing={0} className={classes.headerContainer}>
                            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.textGrid}>
                                <h2>Forge hooks</h2>
                                <MagicTable columns={forgeHookColumns} rows={forgeHookRows} />
                                <br />
                                <h2>SharePoint hooks</h2>
                                <MagicTable columns={sharePointHookColumns} rows={sharePointHookRows} />
                                <br />
                                <h2>Storages</h2>
                                <MagicTable columns={storageColumns} rows={storageRows} />
                                <br />
                                <h2>Nodes</h2>
                                <MagicTable columns={nodeColumns} rows={nodeRows} />
                                <br />
                                <Button onClick={async () => await syncNodes()}>Sync nodes (db help)</Button>
                                <Button onClick={async () => await deleteForgeHooks()}>Delete forge hooks</Button>
                            </Grid>
                        </Grid>
                    </>
                )}
            />
        </Switch>
    );
};

export default ManagementPage;
