import { createStyles, Grid, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link, Route, Switch } from "react-router-dom";
import messages from "./messages";
import { onPageTitle } from "@iolabs/app";
import { useDispatch } from "react-redux";
import { DispatchAction } from "@iolabs/redux-utils";
import { getMainJobs, runMainJob } from "../../../api/client";
import { MainJob } from "../../../api/types";
import { Column, MagicTable } from "../../../components/Table/MagicTable";
import Button from "@material-ui/core/Button";
import { FormDialog } from "./FormModal";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerContainer: {
            minHeight: "25vh",
            alignItems: "stretch",
        },
        projectContainer: {},
        textGrid: {
            padding: theme.spacing(2),
            "& p": {
                fontSize: "12px",
            },
        },
        projectsGrid: {
            padding: theme.spacing(2),
            paddingTop: 0,
            marginBottom: "4rem",
            [theme.breakpoints.up("md")]: {
                marginBottom: "1rem",
            },
        },
        title: {
            color: theme.palette.grey[700],
        },
        subtitle: {
            marginTop: theme.spacing(1),
            fontSize: "14px",
        },
    })
);

const DashboardPage: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();

    // translations
    const intl = useIntl();
    const transPageTitle = intl.formatMessage({ ...messages.pageTitle });

    useEffect(() => {
        dispatch(onPageTitle({ pageTitle: transPageTitle }));
    }, [transPageTitle]);

    // modal
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<number | null>(null);

    const mainJobColumns: Column<MainJob>[] = [
        {
            name: "id",
            label: "ID",
            align: "right",
        },
        {
            name: "includes",
            label: "Include",
        },
        {
            name: "excludes",
            label: "Exclude",
        },
        {
            name: null,
            label: "Master",
            renderer: row => <>{row.master.name}</>,
        },
        {
            name: null,
            label: "Master path",
            renderer: row => <>{row.master.config.RootPath}</>,
        },
        {
            name: null,
            label: "Slave",
            renderer: row => <>{row.slave.name}</>,
        },
        {
            name: null,
            label: "Slave path",
            renderer: row => <>{row.slave.config.RootPath}</>,
        },
        {
            name: null,
            label: "Active",
            renderer: row => <>{row.active ? row.expression : "None"}</>,
        },
        {
            name: null,
            label: "",
            renderer: ({ id }) => (
                <Button onClick={() => runMainJob(id).then(_ => alert("Added to queue"))}>Sync</Button>
            ),
        },
        {
            name: null,
            label: "",
            renderer: ({ id }) => (
                <Button
                    onClick={() => {
                        setSelectedId(id);
                        setModalOpen(true);
                    }}
                >
                    Edit
                </Button>
            ),
        },
    ];

    // table data
    const [rows, setRows] = useState<MainJob[] | null>(null);

    const loadRows = useCallback(async () => {
        setRows(await getMainJobs());
    }, [setRows]);

    useEffect(() => {
        loadRows();
    }, []);

    return (
        <Switch>
            <Route
                render={() => (
                    <>
                        <Grid container spacing={0} className={classes.headerContainer}>
                            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.textGrid}>
                                <MagicTable columns={mainJobColumns} rows={rows} />
                                <br />
                                <Button
                                    onClick={() => {
                                        setSelectedId(null);
                                        setModalOpen(true);
                                    }}
                                >
                                    Add
                                </Button>
                                <Button component={Link} to="/history">
                                    History
                                </Button>
                                <Button component={Link} to="/management">
                                    Management
                                </Button>
                            </Grid>
                        </Grid>
                        <FormDialog open={modalOpen} handleClose={() => setModalOpen(false)} id={selectedId} />
                    </>
                )}
            />
        </Switch>
    );
};

export default DashboardPage;
