import { Container, createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import PublicHeader from "../Header/PublicHeader";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            height: "calc(100vh - 86px)",
            [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
                height: "calc(100vh - 76px)",
            },
            [theme.breakpoints.up("sm")]: {
                height: "calc(100vh - 96px)",
            },
        },
        noPadding: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    })
);

interface IPublicLayoutProps extends React.HTMLAttributes<HTMLElement> {
    fullWidth?: boolean;
    noPadding?: boolean;
}

const PublicLayout: React.FC<IPublicLayoutProps> = (props: IPublicLayoutProps) => {
    const { fullWidth, noPadding, children } = props;
    const classes = useStyles();

    return (
        <>
            <PublicHeader />
            <Container
                component="main"
                maxWidth={fullWidth ? false : "lg"}
                className={clsx(classes.main, {
                    [classes.noPadding]: noPadding,
                })}
            >
                {children}
            </Container>
        </>
    );
};

export default PublicLayout;
