import React, { useCallback } from "react";
import { Box, Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Theme } from "@material-ui/core";
import { Form } from "react-final-form";
import { PlatformConfig } from "../../../api/types";
import { updatePlatformConfig } from "../../../api/client";
import imgBim360 from "../../../assets/images/logo-bim360.png";
import imgSharepoint from "../../../assets/images/logo-sharepoint.png";
import { makeStyles } from "@material-ui/styles";
import { TextField } from "mui-rff";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            "& .MuiDialog-paper": {
                maxWidth: "60vh",
                minWidth: "60vh",
                maxHeight: "60vh",
                minHeight: "60vh",
            },
        },
        dialogImages: {
            padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
        },
        dialogBox: {
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "space-between",
            "& > div": {
                marginBottom: theme.spacing(1),
                [theme.breakpoints.up("md")]: {
                    flexBasis: "48%",
                },
            },
        },
        dialogActions: {
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            "& button": {
                textTransform: "none",
                margin: "0 4px 0 16px",
            },
        },
    })
);

interface Props {
    open: boolean;
    handleClose: () => void;
    initialValues: PlatformConfig;
}

export const FormModal: React.FC<Props> = ({ open, handleClose, initialValues }) => {
    const classes = useStyles();

    const onSubmit = useCallback(
        v => {
            updatePlatformConfig(initialValues.id, v.clientId, v.clientSecret).then(() => {
                alert("Application will be restarted!");
                window.location.reload();
            });
        },
        [initialValues]
    );

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="edit-dialog-title" className={classes.dialog}>
            <Form onSubmit={onSubmit} initialValues={initialValues}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box className={classes.dialogImages}>
                            {initialValues.type === "Forge" ? (
                                <img src={imgBim360} alt="BIM360" height={150} />
                            ) : (
                                <img src={imgSharepoint} alt="Sharepoint" height={150} />
                            )}
                        </Box>
                        <DialogTitle id="edit-dialog-title">
                            {initialValues.type === "Forge" ? "Autodesk BIM 360 Docs" : "Sharepoint"}
                        </DialogTitle>
                        <DialogContent>
                            <Box className={classes.dialogBox}>
                                <TextField
                                    name="clientId"
                                    label="Client ID"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                />
                                <TextField
                                    name="clientSecret"
                                    label="Client secret"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button color="default" variant="contained" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button type="submit" color="primary" variant="contained">
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Form>
        </Dialog>
    );
};
