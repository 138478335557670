import { Typography } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import PrivateContainer from "../../../components/Layout/PrivateContainer";
import messages from "./messages";

// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         root: {},
//     })
// );

const MyProfilePage: React.FC = () => {
    // const classes = useStyles();

    // translations
    const intl = useIntl();
    const transPageTitle = intl.formatMessage({ ...messages.pageTitle });

    return (
        <PrivateContainer stylesProps={{ bgSettings: true }}>
            <Typography variant="h4">{transPageTitle}</Typography>
        </PrivateContainer>
    );
};

export default MyProfilePage;
