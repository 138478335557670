import { setConfig as setKeycloakConfig } from "@iolabs/keycloak";
import { setConfig as setApiConfig } from "@iolabs/api-client";

export type EnvType = "test" | "development" | "production";

export interface IConfig {
    envType: EnvType;
    content: {
        authorWebUrl: string;
        clientWebUrl: string;
    };
    keycloak: {
        clientId: string;
        realm: string;
        realmUrl: string;
    };
    build: {
        lastUpdate: string;
        lastVersion: string;
    };
}

const config: IConfig = {
    envType: process.env.REACT_APP_ENV_TYPE as EnvType,
    content: {
        authorWebUrl: process.env.REACT_APP_AUTHOR_WEB_URL as string,
        clientWebUrl: process.env.REACT_APP_CLIENT_WEB_URL as string,
    },
    keycloak: {
        clientId: process.env.REACT_APP_SECURITY_CLIENT_ID as string,
        realm: process.env.REACT_APP_SECURITY_REALM_NAME as string,
        realmUrl: process.env.REACT_APP_SECURITY_REALM_URL as string,
    },
    build: {
        lastUpdate: process.env.REACT_APP_BUILD_LAST_UPDATE as string,
        lastVersion: process.env.REACT_APP_BUILD_LAST_VERSION as string,
    },
};

export default config;

export const configureModules = () => {
    // keycloak
    setKeycloakConfig({
        clientId: process.env.REACT_APP_SECURITY_CLIENT_ID as string,
        realm: process.env.REACT_APP_SECURITY_REALM_NAME as string,
        realmUrl: process.env.REACT_APP_SECURITY_REALM_URL as string,
    });

    // api
    setApiConfig({
        baseUrl: process.env.REACT_APP_API_BASE_URL as string,
    });
};
