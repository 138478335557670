/**
 * Custom theme forked form MUI default theme
 * https://material-ui.com/customization/default-theme/
 */

import { withStyles } from "@material-ui/core";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

export const fontFamily = {
    primary: "Arial",
    secondary: "Heebo",
};

const arial = {
    fontFamily: fontFamily.primary,
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `
        local("Arial"),
        url("./assets/fonts/Arial.ttf") format('truetype'),
        url("./assets/fonts/Arial.woff") format("woff"),
        url("./assets/fonts/Arial.woff2") format("woff2"),
    `,
};

export const GlobalCss = withStyles({
    "@global": {
        "@font-fase": [arial],
        body: {
            margin: 0,
        },
        "html, body, #root": {
            height: "100%",
        },
    },
})(() => null);

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#377dff",
            // light: "",
            // dark: "",
            // contrastText: "",
            100: "#eff4f 7",
        },
        secondary: {
            main: "#ffcd4b",
            // light: "",
            // dark: "",
            // contrastText: "",
        },
        success: {
            main: "#62d493",
            // light: "",
            // dark: "",
            // contrastText: "",
        },
        warning: {
            main: "#ffcd4b",
            // light: "",
            // dark: "",
            // contrastText: "",
        },
        error: {
            main: "#ff0000",
            // light: "",
            // dark: "",
            // contrastText: "",
        },
        text: {
            primary: "#7a7a7a",
            // secondary: "",
            // disabled: "",
            // hint: "",
        },
        grey: {
            // 50: "",
            100: "#f1f4f5", // private page bg color
            200: "#e5e5e5", // header text color
            300: "#cbcbcb",
            400: "#aeaeae",
            500: "#808080", // header inactive text (public layout)
            // 600: "",
            700: "#707070", // connection line color between bim360 - sharepoint
            // 800: "",
            // 900: "",
            // A100: "",
            // A200: "",
            // A400: "",
            // A700: "",
        },
        common: {
            white: "#fff",
            black: "#000",
        },
        background: {
            // paper: "",
            // default: "",
        },
        action: {
            // active: "",
            // hover: "",
            // hoverOpacity: number,
            // selected: "",
            // selectedOpacity: number,
            // disabled: "",
            // disabledBackground: "",
            // disabledOpacity: number,
            // focus: "",
            // focusOpacity: number,
            // activatedOpacity: number,
        },
        type: "light", // theme style switching
    },
    typography: {
        htmlFontSize: 16,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            `${fontFamily.primary}`,
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    shape: {
        borderRadius: 5,
    },
    spacing: factor => `${0.6 * factor}rem`, // bootstrap strategy
    props: {
        MuiButton: {
            size: "small",
        },
        MuiFilledInput: {
            margin: "dense",
        },
        MuiFormControl: {
            margin: "dense",
        },
        MuiFormHelperText: {
            margin: "dense",
        },
        MuiIconButton: {
            size: "small",
        },
        MuiInputBase: {
            margin: "dense",
        },
        MuiInputLabel: {
            margin: "dense",
        },
        MuiListItem: {
            dense: true,
        },
        MuiOutlinedInput: {
            margin: "dense",
        },
        MuiFab: {
            size: "small",
        },
        MuiTable: {
            size: "small",
        },
        MuiTextField: {
            margin: "dense",
        },
        MuiToolbar: {
            variant: "dense",
        },
    },
});

theme.typography.h1 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 700,
    fontSize: "3.75rem",
    lineHeight: 1.167,
    letterSpacing: "-0.01562em",

    [theme.breakpoints.down("sm")]: {
        fontSize: "2.75rem",
    },
};

theme.typography.h2 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: "3rem",
    lineHeight: 1.2,
    letterSpacing: "-0.00833em",

    [theme.breakpoints.down("sm")]: {
        fontSize: "2.5rem",
    },
};

theme.typography.h3 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: "2.5rem",
    lineHeight: 1.167,
    letterSpacing: "0em",

    [theme.breakpoints.down("sm")]: {
        fontSize: "2rem",
    },
};

theme.typography.h4 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 700,
    fontSize: "1.5625rem",
    lineHeight: 1.235,
    letterSpacing: "0.00735em",
    color: theme.palette.text.primary,

    [theme.breakpoints.down("sm")]: {
        fontSize: "1.25rem",
    },
};

theme.typography.h5 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: "1.5rem",
    lineHeight: 1.334,
    letterSpacing: "0em",

    [theme.breakpoints.down("sm")]: {
        fontSize: "1.25rem",
    },
};

theme.typography.h6 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 500,
    fontSize: "1.25rem",
    lineHeight: 1.6,
    letterSpacing: "0.0075em",

    [theme.breakpoints.down("sm")]: {
        fontSize: "1.25rem",
    },
};

theme.typography.subtitle1 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: "1.875rem",
    lineHeight: 1.15,
    letterSpacing: "0.00938em",

    [theme.breakpoints.down("sm")]: {
        fontSize: "1.5625rem",
    },
};

theme.typography.subtitle2 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: 1.57,
    letterSpacing: "0.00714em",

    [theme.breakpoints.down("sm")]: {},
};

theme.typography.body1 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 1.5,
    letterSpacing: "0.00938em",

    [theme.breakpoints.down("sm")]: {},
};

theme.typography.body2 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.43,
    letterSpacing: "0.01071em",

    [theme.breakpoints.down("sm")]: {},
};

theme.typography.button = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    textTransform: "uppercase",

    [theme.breakpoints.down("sm")]: {},
};

theme.typography.caption = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: 1.66,
    letterSpacing: "0.03333em",

    [theme.breakpoints.down("sm")]: {},
};

theme.typography.overline = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 2.66,
    letterSpacing: "0.08333em",
    textTransform: "uppercase",

    [theme.breakpoints.down("sm")]: {},
};

theme.mixins.toolbar = {
    minHeight: 86,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
        minHeight: 76,
    },
    [theme.breakpoints.up("sm")]: {
        minHeight: 96,
    },
};

theme.overrides = {
    MuiCssBaseline: {
        "@global": {
            body: {
                backgroundColor: theme.palette.common.white,
            },
        },
    },
    MuiButton: {
        contained: {
            color: theme.palette.text.primary,
        },
    },
};

export default theme;
