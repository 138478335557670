import { Box, createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    })
);

interface IPublicContainerProps extends React.HTMLAttributes<HTMLElement> {}

const PublicContainer: React.FC<IPublicContainerProps> = (props: IPublicContainerProps) => {
    const { children } = props;
    const classes = useStyles();

    return <Box className={classes.root}>{children}</Box>;
};

export default PublicContainer;
