import { Box, Container, createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import imgConnection from "../../assets/images/connection.png";
import imgSettings from "../../assets/images/settings.svg";

type StylesProps = {
    bgConnection?: boolean;
    bgSettings?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: (props: StylesProps | undefined) => ({
            height: "100%",
            paddingTop: theme.spacing(4),
            paddingLeft: theme.spacing(2),
            background: props?.bgConnection
                ? `url(${imgConnection}) no-repeat`
                : props?.bgSettings
                ? `url(${imgSettings}) no-repeat`
                : "none",
            backgroundSize: props?.bgConnection ? "45%" : props?.bgSettings ? "60%" : "inherit",
            backgroundPosition: props?.bgConnection ? "95% 95%" : props?.bgSettings ? "bottom right" : "inherit",
        }),
        container: {
            marginLeft: 0,
        },
    })
);

interface IPrivateContainerProps extends React.HTMLAttributes<HTMLElement> {
    stylesProps?: StylesProps;
}

const PrivateContainer: React.FC<IPrivateContainerProps> = (props: IPrivateContainerProps) => {
    const { children, stylesProps } = props;

    const classes = useStyles(stylesProps);

    return (
        <Box className={classes.root}>
            <Container maxWidth="md" className={classes.container}>
                {children}
            </Container>
        </Box>
    );
};

export default PrivateContainer;
