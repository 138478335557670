import { Box, Button, createStyles, Paper, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import imgCheck from "../../../assets/images/icon-check-sm.svg";
import imgBim360 from "../../../assets/images/logo-bim360.png";
import imgSharepoint from "../../../assets/images/logo-sharepoint.png";
import PrivateContainer from "../../../components/Layout/PrivateContainer";
import messages from "./messages";
import { FormModal } from "./FormModal";
import { PlatformConfig } from "../../../api/types";
import { getPlatformConfigs } from "../../../api/client";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        authBox: {
            display: "flex",
            flexFlow: "column nowrap",
        },
        paper: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1),
            display: "flex",
            flexFlow: "row wrap",
            alignItems: "center",
            justifyContent: "space-between",
        },
        paperBox: {
            position: "relative",
            display: "flex",
            flexFlow: "row wrap",
            alignItems: "center",
        },
        button: {
            textTransform: "inherit",
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            [theme.breakpoints.up("md")]: {
                minWidth: "8rem",
            },
        },
        imgStatus: {
            position: "absolute",
            left: "2px",
            top: "-4px",
        },
    })
);

const AuthorizationPage: React.FC = () => {
    const classes = useStyles();

    const [selected, setSelected] = React.useState<PlatformConfig | null>(null);

    const [rows, setRows] = useState<PlatformConfig[] | null>(null);

    const loadRows = useCallback(async () => {
        setRows(await getPlatformConfigs());
    }, [setRows]);

    useEffect(() => {
        loadRows();
    }, []);

    // translations
    const intl = useIntl();
    const transPageTitle = intl.formatMessage({ ...messages.pageTitle });

    return (
        <PrivateContainer stylesProps={{ bgSettings: true }}>
            <Typography variant="h4">{transPageTitle}</Typography>

            <Box mt={2} mb={2} className={classes.authBox}>
                {rows?.map(config => (
                    <Paper className={classes.paper}>
                        <Box className={classes.paperBox}>
                            <Box ml={1} mr={2}>
                                <img
                                    src={config.type === "Forge" ? imgBim360 : imgSharepoint}
                                    alt="SharePoint"
                                    height={40}
                                />
                                <img src={imgCheck} alt="Enable" className={classes.imgStatus} />
                            </Box>
                            <Box>
                                <Typography>
                                    <strong>{config.type === "Forge" ? "Autodesk BIM 360 Docs" : "Sharepoint"}</strong>
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.paperBox}>
                            <Box>
                                <Button
                                    variant="contained"
                                    color="default"
                                    className={classes.button}
                                    onClick={() => setSelected(config)}
                                >
                                    Edit
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                ))}
            </Box>

            {selected && <FormModal open={true} handleClose={() => setSelected(null)} initialValues={selected} />}
        </PrivateContainer>
    );
};

export default AuthorizationPage;
