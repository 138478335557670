import { createAction, DispatchAction } from "@iolabs/redux-utils";
import { Hint } from "./reducer";

export enum ActionTypes {
    OnHints = "layout/ON_HINTS",
}

export type OnHintsOptions = {
    hints: Hint[];
};

const Actions = {
    onHints: (options: OnHintsOptions) => createAction(ActionTypes.OnHints, options),
};

export function onHints(options: OnHintsOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onHints(options));
    };
}
