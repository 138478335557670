import {
    createStyles,
    Theme,
    Typography,
    FormControl,
    Input,
    InputLabel,
    Select,
    MenuItem,
    Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import PrivateContainer from "../../../components/Layout/PrivateContainer";
import messages from "./messages";
import { getSyncLogs } from "../../../api/client";
import { SyncLog, SyncLogQuery } from "../../../api/types";
import { Column, MagicTable } from "../../../components/Table/MagicTable";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    })
);

const columns: Column<SyncLog>[] = [
    {
        name: "id",
        label: "ID",
        align: "right",
    },
    {
        name: "source",
        label: "Source",
        renderer: row => <span>{row.source?.name}</span>,
    },
    {
        name: "destination",
        label: "Destination",
        renderer: row => <span>{row.destination?.name}</span>,
    },
    {
        name: "level",
        label: "Level",
    },
    {
        name: "action",
        label: "Action",
    },
    {
        name: "message",
        label: "Message",
    },
    {
        name: "platform",
        label: "Platform",
    },
    {
        name: "createdAt",
        label: "Created at",
    },
];

const LogFilePage: React.FC = () => {
    const classes = useStyles();

    // translations
    const intl = useIntl();
    const transPageTitle = intl.formatMessage({ ...messages.pageTitle });

    const [filter, setFilter] = useState<SyncLogQuery>({ level: "", action: "" });

    const [rows, setRows] = useState<SyncLog[] | null>(null);

    useEffect(() => {
        getSyncLogs(filter).then(data => setRows(data));
    }, [filter]);

    return (
        <PrivateContainer stylesProps={{ bgSettings: true }}>
            <Typography variant="h4">{transPageTitle}</Typography>

            <div>
                <FormControl className={classes.formControl}>
                    <InputLabel>Level</InputLabel>
                    <Select
                        value={filter?.level}
                        onChange={e => setFilter({ ...filter, level: e.target.value as number })}
                    >
                        <MenuItem value={""}>All</MenuItem>
                        <MenuItem value={0}>Info</MenuItem>
                        <MenuItem value={1}>Warning</MenuItem>
                        <MenuItem value={2}>Error</MenuItem>
                        <MenuItem value={3}>Critical</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel>Action</InputLabel>
                    <Input value={filter.action} onChange={e => setFilter({ ...filter, action: e.target.value })} />
                </FormControl>
                <Button variant="contained" onClick={() => setFilter({ level: "", action: "" })}>
                    Reset
                </Button>
            </div>

            <MagicTable columns={columns} rows={rows} />
        </PrivateContainer>
    );
};

export default LogFilePage;
