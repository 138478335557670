import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import { StoreProvider } from "../../redux";
import { SecurityProvider } from "@iolabs/keycloak";
import theme, { GlobalCss } from "../../utils/Theming";
import { LanguageProvider } from "@iolabs/language-provider";
import { GlobalDialogProvider } from "@iolabs/wip-dialog";
import PageRouter from "../../pages/PageRouter/PageRouter";
import de from "./../../translations/de.json";
import en from "./../../translations/en.json";
import App from "./App";

interface IProps {}

const securityProviderInitConfig = {
    onLoad: "check-sso",
};

class AppWrapper extends React.Component<IProps> {
    public render() {
        return (
            <StoreProvider>
                <SecurityProvider initConfig={securityProviderInitConfig}>
                    <ThemeProvider theme={theme}>
                        <LanguageProvider
                            defaultLocale={"en"}
                            messages={{
                                de,
                                en,
                            }}
                        >
                            <GlobalDialogProvider>
                                <App globalCss={<GlobalCss />}>
                                    <PageRouter />
                                </App>
                            </GlobalDialogProvider>
                        </LanguageProvider>
                    </ThemeProvider>
                </SecurityProvider>
            </StoreProvider>
        );
    }
}

export default AppWrapper;
