import {
    AppBar,
    Box,
    Button,
    createStyles,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    SwipeableDrawer,
    Theme,
    Toolbar,
    Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { Path, publicRoutes } from "../../pages/PageRouter/PageRouter";
import { fontFamily } from "../../utils/Theming";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: "relative",
            backgroundColor: theme.palette.common.white,
            borderBottom: `1px solid ${theme.palette.grey["200"]}`,
            boxShadow: `0px 3px 5px -1px rgba(229, 229, 229, 0.2), 
                        0px 6px 10px 0px rgba(229, 229, 229, 0.14),
                        0px 1px 18px 0px rgba(229, 229, 229, 0.12)`,

            minHeight: 86,
            [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
                minHeight: 76,
            },
            [theme.breakpoints.up("sm")]: {
                minHeight: 96,
            },
        },
        toolbar: {
            flex: 1,
            [theme.breakpoints.up("sm")]: {
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
            },
        },
        inline: {
            display: "inline",
        },
        flex: {
            display: "flex",
            [theme.breakpoints.down("sm")]: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            },
        },
        link: {
            textDecoration: "none",
            color: "inherit",
        },
        productLogo: {
            display: "inline-block",
            borderLeft: `1px solid ${theme.palette.grey["A100"]}`,
            marginLeft: 32,
            paddingLeft: 24,
            [theme.breakpoints.up("md")]: {
                paddingTop: "1.5em",
            },
        },
        companyBox: {
            zIndex: 10,
        },
        company: {
            fontFamily: fontFamily.secondary,
            fontWeight: 900,
            textTransform: "lowercase",
            display: "inline-block",
            color: theme.palette.primary.main,
            fontSize: "1.5rem",
            [theme.breakpoints.up("md")]: {
                display: "none",
                // paddingTop: theme.spacing(1),
                // fontSize: "1.25rem",
            },
        },
        iconContainer: {
            display: "none",
            [theme.breakpoints.down("sm")]: {
                display: "block",
            },
        },
        iconButton: {
            float: "right",
        },
        swipeableDrawer: {
            "& .MuiDrawer-paper": {
                minWidth: "60%",
            },
        },
        tabContainer: {
            flex: 1,
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
        list: {
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "center",
            flex: 1,
            [theme.breakpoints.up("md")]: {
                justifyContent: "flex-start",
            },
            [theme.breakpoints.up("lg")]: {
                justifyContent: "center",
                marginLeft: "-380px",
            },
        },
        listEnd: {
            display: "flex",
            flexFlow: "row nowrap",
            [theme.breakpoints.up("md")]: {
                position: "absolute",
                right: 0,
            },
        },
        listItem: {
            minWidth: "auto",
            textTransform: "none",
            color: theme.palette.grey["500"],
            borderBottom: `2px solid transparent`,
            [theme.breakpoints.up("sm")]: {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            },
            [theme.breakpoints.up("md")]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
            [theme.breakpoints.up("lg")]: {
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
            },
        },
        listItemActive: {
            color: theme.palette.common.black,
            borderBottomColor: theme.palette.primary.main,
        },
        listItemSignUp: {
            "&:hover": {
                backgroundColor: "inherit",
            },
            "& button": {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.common.black,
                borderColor: theme.palette.common.black,
                "&:hover": {
                    backgroundColor: theme.palette.grey["500"],
                    borderColor: theme.palette.grey["500"],
                    boxShadow: "none",
                },
                "&:active": {
                    boxShadow: "none",
                    backgroundColor: theme.palette.grey["500"],
                    borderColor: theme.palette.grey["500"],
                },

                [theme.breakpoints.up("md")]: {
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                },
                [theme.breakpoints.up("lg")]: {
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                },
            },
        },
        drawerList: {},
        drawerListItem: {
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        },
        drawerListItemActive: {
            backgroundColor: theme.palette.grey["200"],
        },
    })
);

interface IPublicHeader {}

const PublicHeader: React.FC<IPublicHeader> = () => {
    const classes = useStyles();
    const location = useLocation();

    const [mobileMenu, setMobileMenu] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleMobileMenuOpen = () => {
        setMobileMenu(true);
    };

    const handleMobileMenuClose = () => {
        setMobileMenu(false);
    };

    return (
        <AppBar position="absolute" color="default" component="header" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <Grid container spacing={0} alignItems="baseline">
                    <Grid item xs={12} className={classes.flex}>
                        <Box className={clsx(classes.companyBox, classes.inline)}>
                            <Typography variant="h6" color="inherit" noWrap>
                                <RouterLink to={Path.ROOT} className={classes.link}>
                                    <span className={classes.company}>Mapper</span>
                                </RouterLink>
                            </Typography>
                        </Box>

                        <>
                            <Box className={classes.iconContainer}>
                                <IconButton
                                    onClick={handleMobileMenuOpen}
                                    className={classes.iconButton}
                                    color="inherit"
                                    aria-label="Menu"
                                >
                                    <MenuIcon fontSize="large" />
                                </IconButton>
                            </Box>
                            <Box className={classes.tabContainer}>
                                <SwipeableDrawer
                                    anchor="left"
                                    open={mobileMenu}
                                    onClose={handleMobileMenuClose}
                                    onOpen={handleMobileMenuOpen}
                                    className={classes.swipeableDrawer}
                                >
                                    <AppBar title="Menu" />
                                    <List className={classes.drawerList}>
                                        <Box>
                                            {publicRoutes.map((route, index) => (
                                                <React.Fragment key={index}>
                                                    {route.path === Path.LOGIN && <Divider />}
                                                    <ListItem
                                                        key={index}
                                                        button
                                                        component={RouterLink}
                                                        exact
                                                        to={{
                                                            pathname: route.path,
                                                            search: location.search,
                                                        }}
                                                        className={classes.drawerListItem}
                                                        activeClassName={classes.drawerListItemActive}
                                                    >
                                                        <ListItemText primary={route.label} />
                                                    </ListItem>
                                                </React.Fragment>
                                            ))}
                                        </Box>
                                    </List>
                                </SwipeableDrawer>
                                <List className={classes.list}>
                                    {publicRoutes.map((route, index) => (
                                        <React.Fragment key={index}>
                                            {!route.isHidden &&
                                                route.path !== Path.LOGIN &&
                                                route.path !== Path.SIGN_UP && (
                                                    <li>
                                                        <ListItem
                                                            key={index}
                                                            button
                                                            component={RouterLink}
                                                            exact
                                                            to={{
                                                                pathname: route.path,
                                                                search: location.search,
                                                            }}
                                                            classes={{ root: classes.listItem }}
                                                            activeClassName={classes.listItemActive}
                                                        >
                                                            <ListItemText primary={route.label} />
                                                        </ListItem>
                                                    </li>
                                                )}
                                        </React.Fragment>
                                    ))}

                                    {location.pathname !== Path.LOGIN && location.pathname !== Path.SIGN_UP && (
                                        <Box className={classes.listEnd}>
                                            {publicRoutes.map((route, index) => (
                                                <React.Fragment key={index}>
                                                    {!route.isHidden &&
                                                        (route.path === Path.LOGIN || route.path === Path.SIGN_UP) && (
                                                            <li>
                                                                <ListItem
                                                                    disableRipple
                                                                    key={index}
                                                                    button
                                                                    component={RouterLink}
                                                                    exact
                                                                    to={{
                                                                        pathname: route.path,
                                                                        search: location.search,
                                                                    }}
                                                                    className={clsx(classes.listItem, {
                                                                        [classes.listItemSignUp]:
                                                                            route.path === Path.SIGN_UP,
                                                                    })}
                                                                    activeClassName={clsx({
                                                                        [classes.listItemActive]:
                                                                            route.path === Path.LOGIN,
                                                                    })}
                                                                >
                                                                    {route.path === Path.SIGN_UP ? (
                                                                        <Button variant="contained">
                                                                            {route.label}
                                                                        </Button>
                                                                    ) : (
                                                                        <ListItemText primary={route.label} />
                                                                    )}
                                                                </ListItem>
                                                            </li>
                                                        )}
                                                </React.Fragment>
                                            ))}
                                        </Box>
                                    )}
                                </List>
                            </Box>
                        </>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default PublicHeader;
