import { DispatchAction } from "@iolabs/redux-utils";
import {
    Box,
    Button,
    createStyles,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Theme,
    Tooltip,
    Typography,
} from "@material-ui/core";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import HelpIcon from "@material-ui/icons/Help";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { Select, TextField } from "mui-rff";
import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import CheckboxTree from "../../../components/CheckboxTree/CheckboxTree";
import { SyncSlaveRadio } from "../../../components/Form/SyncSlaveRadio";
import Hinter from "../../../components/Hinter/Hinter";
import { onHints, useHints } from "../../../redux/layout";
import { Hint } from "../../../redux/layout/reducer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            zIndex: 100,
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            [theme.breakpoints.up("md")]: {
                padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
            },
        },
        mappingBox: {
            flexBasis: "33.33%",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: "510px",
            position: "relative",
            "&::before": {
                content: "''",
                width: "1px",
                height: "700px",
                background: theme.palette.grey["700"],
                display: "block",
                position: "absolute",
                marginLeft: "24px",
                zIndex: 10,
            },
            [theme.breakpoints.up("md")]: {
                flexFlow: "column nowrap",
                justifyContent: "flex-start",
                minHeight: "510px",
                marginTop: "6rem",
                "&::before": {
                    content: "''",
                    width: "50vw",
                    height: "1px",
                    background: theme.palette.grey["700"],
                    display: "block",
                    position: "absolute",
                    zIndex: 10,
                    marginTop: "30px",
                },
            },
        },
        mappingPaper: {
            [theme.breakpoints.up("md")]: {
                maxWidth: "350px",
                marginLeft: theme.spacing(4),
                marginRight: theme.spacing(4),
            },
            [theme.breakpoints.up("lg")]: {
                marginLeft: theme.spacing(8),
                marginRight: theme.spacing(8),
            },
        },
        showButtonBox: {
            marginRight: theme.spacing(1),
        },
        showButton: {
            "& svg": {
                zIndex: 20,
                fontSize: "2.6rem",
                fill: theme.palette.grey["300"],
                background: theme.palette.grey["100"],
                borderRadius: "50px",
            },
            [theme.breakpoints.up("md")]: {
                "& svg": {
                    fontSize: "3.6rem",
                },
            },
        },
        showButtonActive: {
            "& svg": {
                fill: theme.palette.secondary.main,
            },
            "&::after": {
                content: "''",
                width: "40px",
                height: "1px",
                background: theme.palette.grey["700"],
                display: "block",
                position: "absolute",
                marginLeft: "10px",
                zIndex: 10,
            },
            [theme.breakpoints.up("sm")]: {
                "&::after": {
                    width: "300px",
                    left: 0,
                },
            },
            [theme.breakpoints.up("md")]: {
                "&::after": {
                    width: "1px",
                    height: "50px",
                    marginTop: "40px",
                    marginLeft: "32px",
                },
            },
        },
        syncBox: {
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "space-between",
            "& > div": {
                marginBottom: theme.spacing(1.5),
            },
            "& > div:nth-child(1)": {
                flexBasis: "58%",
            },
            "& > div:nth-child(2)": {
                flexBasis: "38%",
            },
            "& > div:nth-child(3)": {
                flexBasis: "58%",
            },
        },
        dirBox: {
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-evenly",
        },
        formControl: {
            marginTop: "50px",
            marginBottom: theme.spacing(1),
            "& .MuiFormLabel-root.Mui-focused": {
                color: theme.palette.common.black,
            },
        },
        label: {
            top: "-40px",
            left: "-14px",
            color: theme.palette.common.black,
            pointerEvents: "auto",
            "& span": {
                fontWeight: 700,
                lineHeight: 1,
                textTransform: "none",
                letterSpacing: 0,
            },
        },
        labelMapping: {
            top: "-30px",
        },
        input: {
            pointerEvents: "auto",
            backgroundColor: theme.palette.primary["100"],
        },
        notchedOutline: {
            borderColor: theme.palette.primary["100"],
        },
        autocomplete: {
            "& .MuiFormControl-marginDense": {
                marginTop: 0,
            },
        },
        helpIcon: {
            fontSize: "1.5rem",
            fill: "rgba(122, 122, 122, 0.26)",
            margin: "4px 0 0 2px",
            cursor: "help",
        },
        buttons: {
            marginTop: theme.spacing(4),
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            "& button": {
                textTransform: "none",
                flexBasis: "50%",
                "&:nth-child(1)": {
                    marginRight: theme.spacing(1),
                },
                "&:nth-child(2)": {
                    marginLeft: theme.spacing(1),
                },
            },
        },
    })
);

const times = new Array(24).fill(0).map((a, i) => i);

const autocompleteOptions = [
    { id: 1, title: ".IFC" },
    { id: 2, title: ".RVT" },
    { id: 3, title: ".DWG" },
    { id: 4, title: ".PNG" },
    { id: 5, title: ".JPG" },
    { id: 6, title: ".PDF" },
];

interface Props {
    active: boolean;
    values: any;
}

export const MappingBox: FC<Props> = ({ active, values }) => {
    const classes = useStyles();
    const hints: Hint[] = useHints();
    const dispatch = useDispatch<DispatchAction>();

    // handle hints states for hinter component
    useEffect(() => {
        // mapping name filled
        if (values?.name && hints.includes(Hint.mappingName)) {
            dispatch(onHints({ hints: [Hint.mappingOptions] }));
        }
        // mapping sync options selected
        if (values?.repeat && values?.time && hints.includes(Hint.mappingOptions)) {
            dispatch(onHints({ hints: [Hint.mappingDirection] }));
        }
        // mapping sync direction selected
        if (values?.syncSlave && hints.includes(Hint.mappingDirection)) {
            dispatch(onHints({ hints: [Hint.mappingIncludes] }));
        }
        // mapping includes selected
        if (values?.includes?.length > 0 && hints.includes(Hint.mappingIncludes)) {
            dispatch(onHints({ hints: [Hint.mappingExcludes] }));
        }
        // mapping excludes selected
        if (values?.excludes?.length > 0 && hints.includes(Hint.mappingExcludes)) {
            dispatch(onHints({ hints: [] }));
        }
    }, [values]);

    return (
        <>
            <Box className={classes.mappingBox}>
                <Box className={classes.showButtonBox}>
                    <IconButton
                        aria-label="show-mapping"
                        // onClick={handleToggleMapping}
                        className={clsx(classes.showButton, {
                            [classes.showButtonActive]: active,
                        })}
                    >
                        <AddCircleOutlinedIcon />
                    </IconButton>
                </Box>

                {active && (
                    <Paper className={clsx(classes.paper, classes.mappingPaper)}>
                        <FormControl
                            fullWidth
                            variant="outlined"
                            size="small"
                            margin="dense"
                            className={classes.formControl}
                        >
                            <InputLabel
                                shrink
                                htmlFor="mapping-name"
                                className={clsx(classes.label, classes.labelMapping)}
                            >
                                <Typography variant="overline">Name your mapping</Typography>
                            </InputLabel>

                            {hints.includes(Hint.mappingName) && <Hinter text="Write your mapping name" />}

                            {/* TODO OutlinedInput */}
                            <TextField name="name" id="mapping-name" className={classes.input} />
                        </FormControl>

                        <FormControl
                            fullWidth
                            variant="outlined"
                            size="small"
                            margin="dense"
                            className={classes.formControl}
                        >
                            <InputLabel
                                shrink
                                htmlFor="mapping-sync"
                                className={clsx(classes.label, classes.labelMapping)}
                            >
                                <Typography variant="overline">Select sync options</Typography>
                            </InputLabel>

                            {hints.includes(Hint.mappingOptions) && <Hinter text="Select sync" />}

                            <Box className={classes.syncBox}>
                                <Select
                                    name="repeat"
                                    id="mapping-sync-repeat"
                                    labelId="mapping-sync-repeat-label"
                                    margin="dense"
                                    displayEmpty
                                    className={classes.input}
                                    defaultValue=""
                                >
                                    <MenuItem value="">none</MenuItem>
                                    <MenuItem value="hourly">hourly</MenuItem>
                                    <MenuItem value="daily">daily</MenuItem>
                                    <MenuItem value="weekly">weekly</MenuItem>
                                </Select>

                                <Select
                                    name="time"
                                    id="mapping-sync-time"
                                    labelId="mapping-sync-time-label"
                                    margin="dense"
                                    displayEmpty
                                    className={classes.input}
                                    defaultValue=""
                                >
                                    {times.map(time => (
                                        <MenuItem key={time} value={time}>
                                            {time}:00
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </FormControl>

                        <FormControl
                            fullWidth
                            variant="outlined"
                            size="small"
                            margin="dense"
                            className={classes.formControl}
                        >
                            <InputLabel
                                shrink
                                htmlFor="mapping-sync"
                                className={clsx(classes.label, classes.labelMapping)}
                            >
                                <Typography variant="overline">
                                    Select sync direction
                                    <Tooltip title="Help text for sync direction" placement="top">
                                        <HelpIcon className={classes.helpIcon} />
                                    </Tooltip>
                                </Typography>
                            </InputLabel>

                            {hints.includes(Hint.mappingDirection) && <Hinter text="Select direction" />}

                            <Box className={classes.dirBox}>
                                <SyncSlaveRadio name="syncSlave" />
                            </Box>
                        </FormControl>

                        <FormControl
                            fullWidth
                            variant="outlined"
                            size="small"
                            margin="dense"
                            className={classes.formControl}
                        >
                            <InputLabel
                                shrink
                                htmlFor="mapping-sync"
                                className={clsx(classes.label, classes.labelMapping)}
                            >
                                <Typography variant="overline">
                                    Folders to synchronize (optional)
                                    <Tooltip title="Help text for sync direction" placement="top">
                                        <HelpIcon className={classes.helpIcon} />
                                    </Tooltip>
                                </Typography>
                            </InputLabel>

                            {hints.includes(Hint.mappingIncludes) && <Hinter text="Do you want to specify folders?" />}

                            <CheckboxTree
                                name="includes"
                                rootMode={false}
                                platform={values.master?.type}
                                parentId={values.master?.root[0]?.id}
                            />
                        </FormControl>

                        <FormControl
                            fullWidth
                            variant="outlined"
                            size="small"
                            margin="dense"
                            className={classes.formControl}
                        >
                            <InputLabel shrink htmlFor="slave-formats" className={classes.label}>
                                <Typography variant="overline">
                                    Choose formats not to be synchronized (optional)
                                    <Tooltip title="Help text for your formats" placement="top">
                                        <HelpIcon className={classes.helpIcon} />
                                    </Tooltip>
                                </Typography>
                            </InputLabel>

                            {hints.includes(Hint.mappingExcludes) && (
                                <Hinter text="Do you want to ignore some file formats?" />
                            )}

                            <Select
                                name="excludes"
                                label=""
                                id="slave-formats"
                                multiple
                                className={clsx(classes.input, classes.autocomplete)}
                            >
                                {autocompleteOptions.map((v, i) => (
                                    <MenuItem key={i} value={v.title}>
                                        {v.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Box className={classes.buttons}>
                            <Button variant="contained" color="primary" size="large" type="submit">
                                Save
                            </Button>
                        </Box>
                    </Paper>
                )}
            </Box>
        </>
    );
};
