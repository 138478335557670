import { createStyles, MenuItem, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Select } from "mui-rff";
import React, { FC, useEffect, useState } from "react";
import { getTree } from "../../api/client";
import { StorageType, TreeNode } from "../../api/types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            pointerEvents: "auto",
            backgroundColor: theme.palette.primary["100"],
        },
    })
);

interface Props {
    name: string;
    platform: StorageType | undefined;
}

export const ProjectSelect: FC<Props> = ({ name, platform }) => {
    const classes = useStyles();
    const [projects, setProjects] = useState<TreeNode[]>([]);

    useEffect(() => {
        switch (platform) {
            case StorageType.Forge:
                getTree(platform, null, 2).then(p => setProjects(p[0]?.children || []));
                break;
            case StorageType.SharePoint:
                getTree(platform, null, 1).then(p => setProjects(p));
                break;
        }
    }, [platform]);

    return (
        <Select name={name} margin="dense" className={classes.input} disabled={!platform}>
            {projects &&
                projects.map(project => (
                    <MenuItem key={project.id} value={project.id}>
                        {project.text}
                    </MenuItem>
                ))}
        </Select>
    );
};
