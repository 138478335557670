import { useGlobalDialog } from "@iolabs/wip-dialog";
import {
    Box,
    Button,
    Checkbox,
    createStyles,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    OutlinedInput,
    Tab,
    Tabs,
    Theme,
    Typography,
    useTheme,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect } from "react";
import { useKeycloak } from "react-keycloak";
import { useHistory, useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import imgLogo from "../../../assets/images/logo-mapper-text.svg";
import { Path } from "../../PageRouter/PageRouter";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            display: "flex",
            flexFlow: "column nowrap",
            [theme.breakpoints.up("md")]: {
                flexFlow: "row nowrap",
            },
        },
        grid: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        boxLogo: {
            flex: 1,
            backgroundColor: theme.palette.primary.main,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: theme.spacing(6),
            [theme.breakpoints.up("md")]: {
                padding: 0,
            },
        },
        boxForm: {
            flex: 1,
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "center",
            "& > div": {
                minWidth: "90%",
                width: "90%",
                maxWidth: "90%",
                [theme.breakpoints.up("md")]: {
                    minWidth: "70%",
                    width: "70%",
                    maxWidth: "70%",
                },
                [theme.breakpoints.up("lg")]: {
                    minWidth: "50%",
                    width: "50%",
                    maxWidth: "50%",
                },
            },
        },
        tabs: {
            minWidth: "50%",
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
        },
        tab: {
            padding: 0,
            textTransform: "none",
            borderBottom: `5px solid ${theme.palette.primary["100"]}`,
            "& .MuiTab-wrapper": {
                alignItems: "normal",
            },
            "&:first-child": {
                textAlign: "left",
            },
            "&:last-child": {
                textAlign: "right",
            },
        },
        tabIndicator: {
            height: "5px",
        },
        tabSelected: {
            fontWight: 700,
            fontSize: "1.563rem",
        },
        tabPanel: {
            boxSizing: "border-box",
            flex: "0 0 50%",
            minHeight: "30rem",
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
            "& .MuiBox-root": {
                padding: 0,
            },
        },
        formControl: {
            marginTop: "40px",
        },
        label: {
            top: "-30px",
            left: "-14px",
            color: theme.palette.common.black,
            "& span": {
                fontWeight: 700,
            },
        },
        input: {
            backgroundColor: theme.palette.primary["100"],
        },
        notchedOutline: {
            borderColor: theme.palette.primary["100"],
        },
        checkbox: {},
        submitBtn: {},
    })
);

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            className={classes.tabPanel}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

interface SignUp {
    email: string;
    password: string;
    retypePassword: string;
    showPassword: boolean;
    terms: boolean;
}

const LoginPage: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { keycloak } = useKeycloak();
    const location = useLocation();
    const history = useHistory();
    const { setGlobalDialogOpen } = useGlobalDialog();

    const [value, setValue] = React.useState<number>(0);

    const [values, setValues] = React.useState<SignUp>({
        email: "",
        password: "",
        retypePassword: "",
        showPassword: false,
        terms: false,
    });

    useEffect(() => {
        pathToValue(location.pathname);
    }, [location]);

    const handleChangeForm = (prop: keyof SignUp) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleChangeCheckbox = (prop: keyof SignUp) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [event.target.name]: event.target.checked });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        valueToPath(newValue);
    };

    const handleChangeIndex = (index: number) => {
        valueToPath(index);
    };

    const handleLogin = useCallback(() => {
        history.push(Path.MAPPINGS);
        keycloak.login();
    }, [keycloak]);

    const valueToPath = (value: number) => {
        setValue(value);
        if (value === 0) {
            history.push(Path.SIGN_UP);
        }
        if (value === 1) {
            history.push(Path.LOGIN);
        }
    };

    const pathToValue = (path: string) => {
        if (path === Path.SIGN_UP) {
            setValue(0);
        }
        if (path === Path.LOGIN) {
            setValue(1);
        }
    };

    // translations
    // const intl = useIntl();
    // const transPageTitle = intl.formatMessage({ ...messages.pageTitle });

    return (
        <Box className={classes.root}>
            <Box className={classes.boxForm}>
                <Box>
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        variant="fullWidth"
                        aria-label="full width tabs"
                        classes={{ root: classes.tabs, indicator: classes.tabIndicator }}
                    >
                        <Tab
                            label="Sign Up"
                            classes={{ root: classes.tab, selected: classes.tabSelected }}
                            {...a11yProps(0)}
                        />
                        <Tab
                            label="Login"
                            classes={{ root: classes.tab, selected: classes.tabSelected }}
                            {...a11yProps(1)}
                        />
                    </Tabs>

                    <SwipeableViews
                        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                size="medium"
                                margin="normal"
                                className={classes.formControl}
                            >
                                <InputLabel shrink htmlFor="standard-adornment-email" className={classes.label}>
                                    <Typography variant="overline">E-mail Address</Typography>
                                </InputLabel>
                                <OutlinedInput
                                    id="standard-adornment-email"
                                    type="email"
                                    value={values.email}
                                    onChange={handleChangeForm("email")}
                                    margin="none"
                                    classes={{ notchedOutline: classes.notchedOutline }}
                                    className={classes.input}
                                />
                            </FormControl>

                            <FormControl
                                fullWidth
                                variant="outlined"
                                size="medium"
                                margin="normal"
                                className={classes.formControl}
                            >
                                <InputLabel shrink htmlFor="standard-adornment-password" className={classes.label}>
                                    <Typography variant="overline">Set Password</Typography>
                                </InputLabel>
                                <OutlinedInput
                                    id="standard-adornment-password"
                                    type={values.showPassword ? "text" : "password"}
                                    value={values.password}
                                    onChange={handleChangeForm("password")}
                                    margin="none"
                                    classes={{ notchedOutline: classes.notchedOutline }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    className={classes.input}
                                />
                            </FormControl>

                            <FormControl
                                fullWidth
                                variant="outlined"
                                size="medium"
                                margin="normal"
                                className={classes.formControl}
                            >
                                <InputLabel
                                    shrink
                                    htmlFor="standard-adornment-retype-password"
                                    className={classes.label}
                                >
                                    <Typography variant="overline">Retype Password</Typography>
                                </InputLabel>
                                <OutlinedInput
                                    id="standard-adornment-retype-password"
                                    type={values.showPassword ? "text" : "password"}
                                    value={values.retypePassword}
                                    onChange={handleChangeForm("retypePassword")}
                                    margin="none"
                                    classes={{ notchedOutline: classes.notchedOutline }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    className={classes.input}
                                />
                            </FormControl>

                            <Box mt={1}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.terms}
                                            onChange={handleChangeCheckbox("terms")}
                                            name="terms"
                                            color="primary"
                                            size="medium"
                                            classes={{ root: classes.checkbox }}
                                        />
                                    }
                                    label={
                                        <>
                                            I agree to the{" "}
                                            <Link component={RouterLink} to={Path.TERMS_OF_SERVICES}>
                                                <strong>terms of services</strong>
                                            </Link>
                                        </>
                                    }
                                />
                            </Box>

                            <Box mt={2}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    className={classes.submitBtn}
                                    disabled={!values.terms}
                                    onClick={() => setGlobalDialogOpen(true)}
                                >
                                    Sign up for free
                                </Button>
                            </Box>
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <Button variant="contained" color="primary" size="medium" onClick={handleLogin}>
                                Keyclock Login
                            </Button>
                        </TabPanel>
                    </SwipeableViews>
                </Box>
            </Box>
            <Box className={classes.boxLogo}>
                <img src={imgLogo} alt="Mapper" />
            </Box>
        </Box>
    );
};

export default LoginPage;
