import { Typography } from "@material-ui/core";
import React from "react";
import PublicContainer from "../../../components/Layout/PublicContainer";

// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         root: {},
//     })
// );

const TermsPage: React.FC = () => {
    // const classes = useStyles();
    // const dispatch = useDispatch<DispatchAction>();

    // translations
    // const intl = useIntl();
    // const transPageTitle = intl.formatMessage({ ...messages.pageTitle });

    return (
        <PublicContainer>
            <Typography variant="h1">Terms of services Page</Typography>
        </PublicContainer>
    );
};

export default TermsPage;
