import { Box, Checkbox, createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TreeItem, TreeView } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useField } from "react-final-form";
import { getTree } from "../../api/client";
import { StorageType, TreeNode } from "../../api/types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        treeViewBox: {
            minHeight: "30vh",
            maxHeight: "30vh",
            overflowY: "auto",
            border: `1px solid ${theme.palette.grey["300"]}`,
            borderRadius: theme.shape.borderRadius,
            paddingTop: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        formControlLabel: {
            "& .MuiCheckbox-root": {
                color: theme.palette.grey["300"],
            },
            "& .MuiCheckbox-colorPrimary.Mui-checked": {
                color: theme.palette.primary.main,
            },
        },
        checkbox: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(0.5),
            padding: 0,
            "& svg": {
                width: "0.8em",
                height: "0.8em",
            },
        },
        folderIcon: {
            margin: "0 8px 0 4px",
            fill: theme.palette.grey["300"],
        },
    })
);

export interface RenderTree {
    id: string;
    name: string;
    children?: Record<string, RenderTree>;
    type: string;
    // ----
    path: string;
}

interface ICheckboxTreeProps {
    name: string;
    rootMode: boolean;
    platform: StorageType | null;
    parentId: string | null;
}

const setChildren = (originalTree: Record<string, RenderTree> | undefined, id: string, children: TreeNode[]) => {
    const newTree: Record<string, RenderTree> = { ...originalTree };

    Object.keys(newTree).forEach(nodeId => {
        if (nodeId === id) {
            const newChildren: Record<string, RenderTree> = {};
            children.forEach(newTreeNode => {
                let path = "";
                if (newTreeNode.type === "folder") {
                    path = (newTree[nodeId].path !== "/" ? newTree[nodeId].path : "") + "/" + newTreeNode.text;
                }

                newChildren[newTreeNode.id] = {
                    id: newTreeNode.id,
                    name: newTreeNode.text,
                    type: newTreeNode.type,
                    path,
                };
            });
            newTree[nodeId].children = newChildren;
            return;
        }

        return setChildren(newTree[nodeId].children, id, children);
    });

    return newTree;
};
const X = [];
const CheckboxTree: React.FC<ICheckboxTreeProps> = ({ name, platform, parentId, rootMode }) => {
    const classes = useStyles();
    const { input } = useField<RenderTree[]>(name, { defaultValue: X });

    const [tree, setTree] = useState<Record<string, RenderTree>>({});

    useEffect(() => {
        if (parentId && platform) {
            setTree({ [parentId]: { id: parentId, name: "Root", type: "hub", path: "/" } });
            getTree(platform, parentId).then(newTreeNodes => {
                if (platform === StorageType.Forge) {
                    newTreeNodes = newTreeNodes.filter(node => ["Plans", "Project Files"].includes(node.text));
                }
                setTree(prevState => setChildren(prevState, parentId, newTreeNodes));
            });
        }
    }, [parentId, platform]);

    const [expanded, setExpanded] = useState<string[]>([]);

    const handleToggle = (node: RenderTree) => {
        if (Object.keys(node.children || {}).length === 0) {
            getTree(platform!, node.id).then(newTreeNodes => {
                setTree(prevState => setChildren(prevState, node.id, newTreeNodes));
            });
        }

        setExpanded([...expanded, node.id]);
    };

    const handleCheck = (node: RenderTree, checked: boolean) => {
        if (rootMode) {
            input.onChange(checked ? [node] : []);
        } else {
            input.onChange(
                checked ? [...input.value].concat([node]) : (input.value || []).filter(n => n.id !== node.id)
            );
        }
    };

    const renderTree = (node: RenderTree) => {
        return (
            <TreeItem
                key={node.id}
                nodeId={node.id}
                onClick={() => handleToggle(node)}
                label={
                    <>
                        <Checkbox
                            className={classes.checkbox}
                            color="primary"
                            checked={input.value && !!input.value.find(n => n.id === node.id)}
                            onChange={e => handleCheck(node, e.target.checked)}
                            onClick={e => e.stopPropagation()}
                            disabled={node.type !== "folder"}
                        />
                        {node.name}
                    </>
                }
            >
                {node.children !== undefined &&
                    Object.keys(node.children)
                        .filter(id => node.children![id].type !== "item")
                        .map(id => renderTree(node.children![id]))}
            </TreeItem>
        );
    };

    return (
        <>
            <Box className={classes.treeViewBox}>
                <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    defaultEndIcon={<ChevronRightIcon style={{ visibility: "hidden" }} />}
                    expanded={expanded}
                >
                    {Object.keys(tree).length > 0 && renderTree(tree[Object.keys(tree)[0]])}
                </TreeView>
            </Box>
            <ul style={{ fontSize: "10px" }}>
                {input.value.map((v, i) => (
                    <li key={i}>
                        {v.path}{" "}
                        <a
                            onClick={e => {
                                e.preventDefault();
                                input.onChange((input.value || []).filter(n => n.path !== v.path));
                                return false;
                            }}
                        >
                            &times;
                        </a>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default CheckboxTree;
